import React from 'react';

import DeleteIcon from '@mui/icons-material/Delete';
import {
  Avatar,
  Box,
  IconButton,
  Typography,
} from '@mui/material';

import DefaultPlaceIcon from './default_place_type.png';

const PlaceTypeList = ({ types, handleDeleteType }) => {
  return (
    <Box sx={{ marginTop: 4 }}>
      <Typography variant="h6">Place Types</Typography>
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
        {types.map((type) => (
          <Box
            key={type._id}
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              padding: 1,
              border: '1px solid #ccc',
              borderRadius: 2
            }}
          >
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Avatar
                src={type.imageIconURL || DefaultPlaceIcon}
                alt={type.name}
                sx={{ width: 32, height: 32, marginRight: 1 }}
              />
              <Typography variant="body1">{type.name}</Typography>
            </Box>
            <IconButton
              onClick={() => handleDeleteType(type._id)}
              color="error"
            >
              <DeleteIcon />
            </IconButton>
          </Box>
        ))}
      </Box>
    </Box>
  );
};

export default PlaceTypeList;