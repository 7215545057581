import React from 'react';

import {
  MdAddLocationAlt,
  MdContentCopy,
  MdCreateNewFolder,
  MdDownload,
  MdEdit,
  MdFolderOpen,
  MdFolderShared,
  MdLogout,
  MdManageAccounts,
  MdOutlineListAlt,
  MdPeople,
  MdViewList,
} from 'react-icons/md';

import PublishedExperiencesList from '../Admin/PublishedExperiencesList';
import UsersList from '../Admin/UsersList';
import MediaLibrary from '../Media/MediaLibrary';
import CreatePlace from '../Places/CreatePlace';
import PlacesList from '../Places/PlacesList';
import ProjectViewAll from '../Projects/ProjectViewAll';
import PublishExperience from '../Publish/PublishExperience';
import CreateRoute from '../Routes/CreateRoute';
import RoutesList from '../Routes/RoutesList';
import UserEditProfile from '../User/UserEditProfile';
import useSidebar from './hooks/useSidebar';
import Logo from './Logo';
import { handleLogout } from './services/authService';
import SidebarItem from './SidebarItem';
import SidebarContainer from './styled/SidebarContainer';

const Sidebar = ({
  isCollapsed,
  onCreateExperience,
  onOpenExperience,
  onCollborator,
  onEditExperience,
  onCloneExperience,
  onExportExperience,
  isSignedIn,
  coordinates,
  routePoints,
  setRoutePoints,
  onSetSelectedPlaceCoordinates,
  onSetSelectedRoutePoints,
  mode, setMode
}) => {
  
  
  const storedOwnerId = sessionStorage.getItem("ownerId");
  const storedExperienceId = sessionStorage.getItem("experienceId");
  const {
    openMenu,
    // setOpenMenu,
    isEditingProfile,
    setIsEditingProfile,
    isCreatingPlace,
    setIsCreatingPlace,
    isEditingPlace,
    setIsEditingPlace,
    isCreatingRoute,
    setIsCreatingRoute,
    isEditingRoute,
    setIsEditingRoute,
    showProjectsList,
    setShowProjectsList,
    showPlacesList,
    setShowPlacesList,
    showRoutesList,
    setShowRoutesList,
    isViewUsers,
    setIsViewUsers,
    isViewPublishedExperiences,
    setIsViewPublishedExperiences,
    isPublishDialogOpen,
    setIsPublishDialogOpen,
    isMediaDialogOpen,
    setIsMediaDialogOpen,
    selectedPlace,
    setSelectedPlace,
    selectedRoute,
    setSelectedRoute,
    handleMenuClick,
  } = useSidebar();

  

  const isUserAdmin = JSON.parse(sessionStorage.getItem("isUserAdmin"));


  
  const handleEditPlace = (place) => {
    setSelectedPlace(place);
    setIsEditingPlace(true);
    setMode('EditPlace');
    if (place?.triggerZone) {
      onSetSelectedPlaceCoordinates(place.triggerZone); // Send coordinates to App.js
    }
  };

  const handleEditRoute = (routes) => {
    setSelectedRoute(routes);
    setMode('EditRoute');
    setIsEditingRoute(true);
    // console.log(routes);
    if (routes?.geo?.coordinates) {
      const formatedRoutePoints = routes?.geo?.coordinates?.map(([lng, lat]) => ({ lat, lng }));
      // console.log("formatedRoutePoints",formatedRoutePoints);
      onSetSelectedRoutePoints(formatedRoutePoints);
    }
  };
  // Handle back button in Edit Profile
  const handleBack = () => {
    setIsEditingProfile(false);
    setIsCreatingPlace(false);
    setIsCreatingRoute(false);
    setSelectedPlace(null);
    setSelectedRoute(null); 
    setIsEditingPlace(false);
    setIsEditingRoute(false);
    // setFirstClick(false);
    onSetSelectedRoutePoints(null);
    onSetSelectedPlaceCoordinates(null);
    if(mode === 'CreateRoute' || mode === 'EditRoute' || mode === 'CreatePlace' || mode === 'EditPlace')
    {
      setMode(null);
      window.location.reload();
    }

    
  };

  const openPublishDialog = () => {
    setIsPublishDialogOpen(true); // Open Publish dialog
  };

  const closePublishDialog = () => {
    setIsPublishDialogOpen(false); // Close Publish dialog
  };

  const openMediaDialog = () => {
    setIsMediaDialogOpen(true); // Open Publish dialog
  };
  const closeMediaDialog = () => {
    setIsMediaDialogOpen(false); // Close Publish dialog
  };
  const handleCreateRoute = () => {
    setMode('CreateRoute');
    // setFirstClick(true);
    // onSetSelectedRoutePoints(null);
    // // onSetSelectedPlaceCoordinates(null);
    // setRoutePoints([]);
    // console.log(routePoints);
  }
  const handleCreatePlace = () => {
    setMode('CreatePlace');
    // onSetSelectedRoutePoints(null);
    // onSetSelectedPlaceCoordinates(null);
  }
  return (
    <>
    {isCreatingRoute || isEditingRoute ? (
        <CreateRoute onBack={handleBack} routePoints={routePoints} setRoutePoints={setRoutePoints} isEditing={!!selectedRoute}
        routeData={selectedRoute}/>
      ) : isEditingProfile ? (
        <UserEditProfile onBack={handleBack} userId={storedOwnerId} />
      ) : isCreatingPlace || isEditingPlace ? (
        <CreatePlace onBack={handleBack} coordinates={coordinates} isSignedIn={isSignedIn} userId={storedOwnerId} isEditing={!!selectedPlace}
        placeData={selectedPlace}/>
        
      ) : (
        <SidebarContainer $isCollapsed={isCollapsed}>
          {!isCollapsed && <Logo />}
          {!isCollapsed && (
            <>
              <SidebarItem
                title="Experience"
                subItems={[
                  "Open",
                  "Create",
                  "Collaborators",
                  "Edit",
                  "Clone",
                  "Export",
                ]}
                subItemIcons={[
                  <MdFolderOpen />,
                  <MdCreateNewFolder />,
                  <MdFolderShared />,
                  <MdEdit />,
                  <MdContentCopy />,
                  <MdDownload />,
                ]}
                isOpen={openMenu === "Experience"}
                onClick={() => handleMenuClick("Experience")}
                subItemActions={[
                  onOpenExperience,
                  onCreateExperience,
                  onCollborator,
                  onEditExperience,
                  onCloneExperience,
                  onExportExperience,
                ]}
                isFirstMenu={true}
              />
              <SidebarItem
                title="Places"
                subItems={["Create", "View All"]}
                subItemIcons={[<MdAddLocationAlt/>, <MdOutlineListAlt />]}
                isOpen={openMenu === "Places"}
                onClick={() => handleMenuClick("Places")}
                subItemActions={[() => {setIsCreatingPlace(true);handleCreatePlace();}, () => setShowPlacesList(true)]}
                isFirstMenu={false}
              />
              <SidebarItem
                title="Routes"
                subItems={["Create", "View All"]}
                subItemIcons={[<MdAddLocationAlt/>, <MdOutlineListAlt />]}
                isOpen={openMenu === "Routes"}
                onClick={() => handleMenuClick("Routes")}
                subItemActions={[() => {setIsCreatingRoute(true);handleCreateRoute();}, () => setShowRoutesList(true)]}
                isFirstMenu={false}
              />
              <SidebarItem
                title="Publish"
                subItems={[]}
                subItemIcons={[]}
                isOpen={false}
                onClick={openPublishDialog}
                subItemActions={[]}
                isFirstMenu={false}
              />
              <SidebarItem
                title="Projects"
                subItems={["View All"]}
                subItemIcons={[<MdOutlineListAlt />]}
                isOpen={openMenu === "Projects"}
                onClick={() => handleMenuClick("Projects")}
                subItemActions={[() => setShowProjectsList(true)]}
                isFirstMenu={false}
              />
              <SidebarItem
                title="Media Library"
                subItems={[]}
                subItemIcons={[]}
                isOpen={openMenu === "Media Library"}
                onClick={openMediaDialog}
                subItemActions={[]}
                isFirstMenu={false}
              />
              <SidebarItem
                title="User"
                subItems={["Edit Profile", "Logout"]}
                subItemIcons={[<MdManageAccounts />, <MdLogout />]}
                isOpen={openMenu === "User"}
                onClick={() => handleMenuClick("User")}
                subItemActions={[() => setIsEditingProfile(true), handleLogout]}
                isFirstMenu={false}
              />
              {isUserAdmin && (
              <SidebarItem
                title="Admin"
                subItems={["Users", "Published Experiences"]}
                subItemIcons={[<MdPeople />, <MdViewList />]}
                isOpen={openMenu === "Admin"}
                onClick={() => handleMenuClick("Admin")}
                subItemActions={[() => setIsViewUsers(true), () => setIsViewPublishedExperiences(true)]}
                isFirstMenu={false}
              />
            )}
            </>
          )}
      
        </SidebarContainer>
      )}

      {isViewUsers && (
        <UsersList openViewUsersDialog={isViewUsers} setOpenViewUsersDialog={setIsViewUsers} />
      )}
      {isViewPublishedExperiences && (
        <PublishedExperiencesList openViewPublishedExpDialog={isViewPublishedExperiences} setOpenViewPublishedExpDialog={setIsViewPublishedExperiences} />
      )}
      
      {showProjectsList && (
        <ProjectViewAll openProjectsDialog={showProjectsList} setOpenProjectsDialog={setShowProjectsList} />
      )}
      {showPlacesList && (
        <PlacesList openPlacesDialog={showPlacesList} setOpenPlacesDialog={setShowPlacesList} setSelectedPlace={setSelectedPlace}
        setIsEditingPlace={setIsEditingPlace} onEditPlace={handleEditPlace}/>
      )}
      {showRoutesList && (
        <RoutesList openRoutesDialog={showRoutesList} setOpenRoutesDialog={setShowRoutesList} setSelectedRoute={setSelectedRoute}
        setIsEditingRoute={setIsEditingRoute} onEditRoute={handleEditRoute}/>
      )}
      <PublishExperience open={isPublishDialogOpen} onClose={closePublishDialog} experienceId={storedExperienceId} />
      <MediaLibrary open={isMediaDialogOpen} isSignedIn={isSignedIn} onClose={closeMediaDialog} experienceId={storedExperienceId} parent={'main'} selectedMediaIds={null} onSelectMediaIds={null}/>

    </>
  );

};

export default Sidebar;
