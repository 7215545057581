import React, {
  useEffect,
  useState,
} from 'react';

import axios from 'axios';

import {
  Delete,
  Description,
  Edit,
  PictureAsPdf,
} from '@mui/icons-material';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
} from '@mui/material';

import BASE_URL from '../../config';

const PlacesList = ({ openPlacesDialog, setOpenPlacesDialog, setIsEditingPlace, setSelectedPlace, onEditPlace }) => {
  const [places, setPlaces] = useState([]);
  const [selectedMedia, setSelectedMedia] = useState(null);

  const handleEditPlace = (place) => {
    setSelectedPlace(place);
    setIsEditingPlace(true);
    setOpenPlacesDialog(false);
    onEditPlace(place);
  };

  useEffect(() => {
    const fetchPlaces = async () => {
      try {
        const response = await axios.get(`${BASE_URL}/api/point-of-interest`, {
          headers: { 'Content-Type': 'application/json' },
          withCredentials: true,
        });

        if (response.status === 200 && Array.isArray(response.data)) {
          setPlaces(response.data);
        } else {
          console.error("Unexpected response format or empty places array");
          setPlaces([]);
        }
      } catch (error) {
        console.error("Error fetching places:", error);
        setPlaces([]);
      }
    };

    if (openPlacesDialog) {
      fetchPlaces();
    }
  }, [openPlacesDialog]);

  const handleDeletePlace = async (id) => {
    try {
      await axios.delete(`${BASE_URL}/api/places/${id}`);
      setPlaces((prevPlaces) => prevPlaces.filter((place) => place.id !== id));
    } catch (error) {
      console.error("Error deleting place:", error);
    }
  };

  const handleMediaClick = (media) => {
    if (media.mimetype.startsWith('image/')) {
      setSelectedMedia(media.path); // Show image in dialog
    } else {
      window.open(media.path, '_blank'); // Open non-image files in new tab
    }
  };

  const handleCloseMediaDialog = () => {
    setSelectedMedia(null);
  };

  return (
    <>
      <Dialog open={openPlacesDialog} onClose={() => setOpenPlacesDialog(false)} fullWidth maxWidth="md">
        <DialogTitle>Places</DialogTitle>
        <DialogContent>
          <Table>
            <TableHead>
              <TableRow key={'_dummyKey'}>
                <TableCell>Type</TableCell>
                <TableCell>Name</TableCell>
                <TableCell>Media Items</TableCell>
                <TableCell>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {places.map((place, index) => (
                <TableRow key={index}>
                  <TableCell>
                    <Tooltip title={place.type.name}>
                      <IconButton>
                        <img
                          src={place.type.imageIconURL}
                          alt={place.type.name}
                          style={{ width: 24, height: 24 }}
                        />
                      </IconButton>
                    </Tooltip>
                    {place.type.name}
                  </TableCell>
                  <TableCell>{place.name || "Unnamed Place"}</TableCell>
                  <TableCell>
                    {place.mediaItems.slice(0, 3).map((media, index) => (
                      <div key={index} style={{ display: 'inline-block', margin: 4 }}>
                        {media.mimetype.startsWith('image/') ? (
                          <img
                            src={media.path}
                            alt={`media-${index}`}
                            style={{ width: 40, height: 40, cursor: 'pointer' }}
                            onClick={() => handleMediaClick(media)}
                          />
                        ) : media.mimetype === 'application/pdf' ? (
                          <IconButton onClick={() => handleMediaClick(media)}>
                            <PictureAsPdf style={{ fontSize: 40 }} color="error" />
                          </IconButton>
                        ) : (
                          <IconButton onClick={() => handleMediaClick(media)}>
                            <Description style={{ fontSize: 40 }} color="primary" />
                          </IconButton>
                        )}
                      </div>
                    ))}
                    {place.mediaItems.length > 3 && <span>+{place.mediaItems.length - 3} more</span>}
                  </TableCell>
                  <TableCell>
                    <IconButton
                      color="primary"
                      onClick={() => handleEditPlace(place)}
                    >
                      <Edit />
                    </IconButton>
                    <IconButton
                      color="secondary"
                      onClick={() => handleDeletePlace(place.id)}
                    >
                      <Delete />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenPlacesDialog(false)} variant="outlined">
            Cancel
          </Button>
          <Button variant="contained" onClick={() => (window.location.href = '/poi/create')}>
            Create
          </Button>
        </DialogActions>
      </Dialog>

      {/* Media Preview Dialog */}
      <Dialog open={!!selectedMedia} onClose={handleCloseMediaDialog} maxWidth="sm" fullWidth>
        <DialogContent>
          {selectedMedia && (
            <img src={selectedMedia} alt="Selected Media" style={{ width: '100%' }} />
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseMediaDialog} variant="outlined">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default PlacesList;
