import React, {
  useEffect,
  useState,
} from 'react';

import axios from 'axios';
import mongoose from 'mongoose';
import {
  MdAdd,
  MdDelete,
} from 'react-icons/md';

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  InputAdornment,
  TextField,
} from '@mui/material';

import BASE_URL from '../../config';

const Collaborators = ({ isOpen, onClose, experienceId, experienceName, onAddCollaboratorSuccess }) => {
  const [userId, setUserId] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const storedOwnerId = sessionStorage.getItem("ownerId");
  const [collaborators, setCollaborators] = useState([{}]);

  useEffect(() => {
    if (isOpen && experienceId) {
      fetchCollaborators();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen, experienceId, userId]);

  const fetchCollaborators = async () => {
    try {
      const response = await axios.get(`${BASE_URL}/api/experience/${experienceId}/collaborator`, {
        headers: { 'Content-Type': 'application/json' },
        withCredentials: true,
      });
      if (response.status === 200) {
        // console.log(response.data);
        setCollaborators(response.data);
      } else {
        console.error("Error fetching collaborators");
      }
    } catch (error) {
      console.error("Error fetching collaborators:", error);
      setError("Error fetching collaborators");
    }
  };

  const handleRemoveCollaborator = async (userId) => {
    setLoading(true);
    try {
      const response = await axios.delete(
        `${BASE_URL}/api/experience/${experienceId}/collaborator/${userId}`,
        {
          headers: {
            "Content-Type": "application/json",
          },
          withCredentials: true,
        }
      );
      if (response.status === 200) {
        // Remove the collaborator from state after successful deletion
        setCollaborators((prevCollaborators) =>
          prevCollaborators.filter((collaborator) => collaborator.id !== userId)
        );
      } else {
        setError("Failed to remove collaborator.");
      }
    } catch (err) {
      console.error(err);
      setError("Error removing collaborator.");
    } finally {
      setLoading(false);
    }
  };

  const handleAdd = async () => {
    setLoading(true);
    const storedOwnerId = sessionStorage.getItem("ownerId");

    try {
      // const userIdsArray = userId.split(',')
      const isValidObjectId = (id) => mongoose.Types.ObjectId.isValid(id);

      const userIdsArray = userId.split(',').map(id => id.trim());
      const validUserIds = userIdsArray.filter(isValidObjectId);

      if (validUserIds.length === 0) {
        console.error("No valid user IDs provided.");
        setError("No valid user IDs provided.");
      }
      const response = await axios.post(
        `${BASE_URL}/api/experience/${experienceId}/collaborator`,
        {
          userIds: validUserIds,
          user: { _id: storedOwnerId },
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
          withCredentials: true,
        }
      );

      if (response.status === 200) {
        // console.log(response.data);
        setError("");
        onAddCollaboratorSuccess(response.data);

      } else {
        console.log(response.data.error);
        setError(response?.data?.error?.message ? response?.data?.error?.message : "error in adding collaborator"); // Display the error message
      }
    } catch (error) {
      console.log(error.response?.data?.error?.message ? error.response?.data?.error?.message : "error in adding collaborator");
      setError(error.response?.data?.error?.message ? error.response?.data?.error?.message : "error in adding collaborator");
    } finally {
      setLoading(false);
      setUserId("");
    }
  };



  return (
    <Dialog open={isOpen} onClose={onClose}>
      <DialogTitle>Collaborators</DialogTitle>
      <DialogContent>
        {error && <p className="error-message">{error}</p>}
        <p>Add users IDs below to allow other users to make changes to the '{experienceName}' experience.</p>
        <TextField
          label="Enter a user's ID"
          variant="standard"
          value={userId}
          onChange={(e) => setUserId(e.target.value)}
          fullWidth
          margin="normal"
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <Button
                  onClick={handleAdd}
                  variant="contained"
                  color="primary"
                  style={{ 'marginBottom': '15px' }}
                  disabled={!userId || loading}
                  startIcon={<MdAdd />}
                >
                  Add
                </Button>
              </InputAdornment>
            ),
          }}
        />
        <div>
          <div>
            <h3>My User ID</h3>
            <p>Share <b><span style={{ fontStyle: 'italic' }}>{storedOwnerId}</span></b>{' '} with others users to collaborate on their experiences.</p>

          </div>
          <div>
            {collaborators.length > 0 ? (
              collaborators.map((collaborator, index) => (
                <div
                  key={collaborator.id ? collaborator.id : index}
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    padding: '10px',
                    marginBottom: '10px',
                    borderBottom: '1px solid #ddd',
                  }}
                >
                  {/* Left section: Profile Image and Display Name */}
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    {/* Profile Image */}
                    <img
                      src={`${collaborator.photoURL}?cacheBust=${new Date().getTime()}`}
                      alt={collaborator.displayName}
                      style={{
                        width: '40px',
                        height: '40px',
                        borderRadius: '50%',
                        marginRight: '10px',
                        objectFit: 'cover',
                      }}
                    />
                    {/* Display Name */}
                    <p style={{ margin: 0 }}>{collaborator.displayName}</p>
                  </div>

                  {/* Right section: Remove Button */}
                  <Button
                    variant="contained" // Changed from outlined to contained for visual difference
                    color="secondary" // Use a secondary color (e.g., red) to make it distinct
                    startIcon={<MdDelete />} // Optional: add delete icon for more visual clarity
                    onClick={() => handleRemoveCollaborator(collaborator.id)}
                    style={{
                      backgroundColor: '#f44336', // Red color
                      color: '#fff',
                      fontWeight: 'bold',
                    }}
                    disabled={loading} // Disable button while loading
                  >
                    Remove
                  </Button>
                </div>
              ))
            ) : (
              <p>No collaborators added yet.</p>
            )}
          </div>
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} variant="outlined" disabled={loading}>
          Close
        </Button>
        {/* <Button onClick={handleAdd} variant="contained" color="primary" disabled={!userId || loading}>
          {loading ? "Adding..." : "Add"}
        </Button> */}
      </DialogActions>
    </Dialog>
  );
};

export default Collaborators;
