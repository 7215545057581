import React, {
  useEffect,
  useState,
} from 'react';

import axios from 'axios';

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';

import BASE_URL from '../../config';
import FeatureExperienceForm from './FeatureExperienceForm';

const PublishedExperiencesList = ({ openViewPublishedExpDialog, setOpenViewPublishedExpDialog }) => {
  const [isFeatureDialogOpen, setIsFeatureDialogOpen] = useState(false);
  const [selectedExperience, setSelectedExperience] = useState(null);
    const[experiences,setExperiences]=useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    
    const handleClose = () => {
    setOpenViewPublishedExpDialog(false);
    
  };

  const handleFeatureExperience = async(exp) => {
    if(!exp?.metaData?.featured){
    setSelectedExperience(exp);
    setIsFeatureDialogOpen(true);
    
    }
    else{
    try {
      
      const response = await axios.delete(`${BASE_URL}/api/admin/published-experiences/${exp._id}/feature`, {
        headers: {
          "Content-Type": "application/json",
        },
        withCredentials: true, // Correctly send credentials
      });
  
      // Check for successful response
      if (response.status !== 200) {
        throw new Error('Failed to delete feature');
      }
  
      const data = response.data; // Get data directly from the response
      // alert('Feature created successfully'); // Notify user of success
      // console.log(data); // Log the data returned from the server
      // setExperiences(response.data);
      fetchPublishedExperiences();
      // onClose();
    } catch (error) {
      console.error("Error details:", error); // Log error details for debugging
      // alert(error.response?.data?.error || error.message); // Show a meaningful error message
    }
  }
  };
  const fetchPublishedExperiences = async () => {
    
    const storedUserData = sessionStorage.getItem("userData");
    const userData = storedUserData ? JSON.parse(storedUserData) : null;
    // console.log(userData);
    try {
        const response = await axios.get(`${BASE_URL}/api/admin/published-experiences`, {
        withCredentials: true,
        });
        setExperiences(response.data);
        // console.log(response.data);
    } catch (err) {
        setError('Failed to fetch users');
    } finally {
        setLoading(false);
    }
    };

  useEffect(() => {
   
    fetchPublishedExperiences();
}, []);

  return (
    <Dialog open={openViewPublishedExpDialog} onClose={handleClose} fullWidth maxWidth="md">
      <DialogTitle>Published Experiences</DialogTitle>
      <DialogContent>
      <TableContainer component={Paper} style={{maxHeight:400}}>
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell>Description</TableCell>
                <TableCell>Date</TableCell>
                <TableCell>Version</TableCell>
                <TableCell>Link</TableCell>
                <TableCell>Published by</TableCell>
                <TableCell>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {experiences && experiences?.map((exp,index) => (
                <TableRow key={index}>
                  <TableCell>{exp.name}</TableCell>
                  <TableCell>{exp.description}</TableCell>
                  <TableCell>{new Date(exp.metaData.created_at).toLocaleDateString()}</TableCell>
                  <TableCell>{exp.metaData.version}</TableCell>
                  <TableCell><a href={exp.metaData.shortLink} target="_blank" rel="noopener noreferrer">{exp.metaData.shortLink}</a></TableCell>
                  <TableCell>{exp.metaData.ownerPublicProfile.displayName}</TableCell>
                  <TableCell>
                    <Button color="primary" onClick={() => handleFeatureExperience(exp)}>{exp.metaData.featured ? 'Un-feature' : 'Feature' }</Button>                    
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
      </DialogActions>

      {isFeatureDialogOpen && (
        <FeatureExperienceForm
          open={isFeatureDialogOpen}
          onClose={() => setIsFeatureDialogOpen(false)}
          experience={selectedExperience}
          fetchPublishedExperiences={fetchPublishedExperiences}
        />
      )}
    </Dialog>
  );
};

export default PublishedExperiencesList;
