import React from 'react';

import ItemContainer from './styled/ItemContainer';
import SubMenu from './SubMenu';

const SidebarItem = ({ title, subItems,subItemIcons, isOpen, onClick, subItemActions, isFirstMenu }) => {


  return (
    <div style={{ position: 'relative' }}> {/* Ensure submenu appears correctly */}
    <ItemContainer onClick={onClick}>
        {title}
      </ItemContainer>
      {isOpen && <SubMenu items={subItems} icons={subItemIcons} subItemActions={subItemActions} onClose={onClick} isFirstMenu={isFirstMenu}/>}
  </div>
  );
};

export default SidebarItem;
