import styled from 'styled-components';

export const SubMenuContainer = styled.div`
  position: absolute;
  left: 0;
  top: ${(props) => (props.$openUp && !props.$isFirstMenu ? 'auto' : '100%')};
  bottom: ${(props) => (props.$openUp && !props.$isFirstMenu ? '100%' : 'auto')};
  background: white;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  padding: 10px;
  width: 75%;
  z-index: 1;

  @media (max-width: 768px) {
    width: 60%;
    left: 0;
    top: ${(props) => (props.$openUp && !props.$isFirstMenu ? 'auto' : '100%')};
    bottom: ${(props) => (props.$openUp && !props.$isFirstMenu ? '100%' : 'auto')};
  }
  @media (min-width: 769px) and (max-width: 1024px) {
     width: 60%;
    left: 0;
    top: ${(props) => (props.$openUp && !props.$isFirstMenu ? 'auto' : '100%')};
    bottom: ${(props) => (props.$openUp && !props.$isFirstMenu ? '100%' : 'auto')};
  }
`;

export const SubMenuItem = styled.div`
  padding: 10px 15px;
  color: #000000de;
  cursor: pointer;

  &:hover {
    background: #e8eaed;
  }
`;

export const IconWrapper = styled.div`
  color: #0000008a;
  font-size: 17px;
  font-weight: 500;
  display: flex;
  align-items: center;
`;
