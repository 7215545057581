import styled from 'styled-components';

const SidebarContainer = styled.div`
  width: ${(props) => (props.$isCollapsed ? "0" : "20%")};
  background: white;
  color: black;
  display: flex;
  flex-direction: column;
  transition: width 0.3s ease;

  @media (max-width: 768px) {
    width: ${(props) => (props.$isCollapsed ? "0" : "100%")};
  }

  @media (min-width: 769px) and (max-width: 1024px) {
    width: ${(props) => (props.$isCollapsed ? "0" : "50%")};
  }
`;
export default SidebarContainer;