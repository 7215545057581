import React, {
  useEffect,
  useState,
} from 'react';

import axios from 'axios';
import { MdArrowBack } from 'react-icons/md';
import styled from 'styled-components';

import {
  Avatar,
  Button,
  IconButton,
  TextField,
  Typography,
} from '@mui/material';

import BASE_URL from '../../config'; // Assuming BASE_URL is defined in config
import { showToast } from '../../utils/toast';

const SidebarContainer = styled.div`
  width: ${(props) => (props.$isCollapsed ? "0" : "20%")};
  background: white;
  color: black;
  display: flex;
  flex-direction: column;
  transition: width 0.3s ease;

  @media (max-width: 768px) {
    width: ${(props) => (props.$isCollapsed ? "0" : "100%")};
  }

  @media (min-width: 769px) and (max-width: 1024px) {
    width: ${(props) => (props.$isCollapsed ? "0" : "50%")};
  }
`;

const EditProfileContainer = styled.div`
  padding: 20px;
  font-family: 'Roboto Slab';
`;

const EditProfileHeader = styled.header`
  display: flex;
  align-items: center;
  background-color: lightgrey;
  padding: 10px;
  margin-bottom:10px;
`;


const ProfileForm = styled.form`
  display: flex;
  flex-direction: column;
  margin-top: 10px;
`;

const FormField = styled.div`
  margin-bottom: 20px;
`;

const ProfileDetails = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
`;

const ProfileImage = styled(Avatar)`
  margin-right: 15px;
`;

const UserEditProfile = ({ onBack, userId }) => {
  const [profile, setProfile] = useState(null);
  const [bio, setBio] = useState('');
  const [error, setError] = useState('');

  // Fetch profile on load
  useEffect(() => {
    const fetchProfile = async () => {
      try {
        const response = await axios.get(`${BASE_URL}/api/user`, {
          withCredentials: true,
        });
        setProfile(response.data);
        setBio(response.data.bio);
      } catch (error) {
        console.error('Error fetching profile:', error);
      }
    };
    fetchProfile();
  }, [userId]);

  // Handle form submission for profile update
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.put(
        `${BASE_URL}/api/user/${userId}`,
        { bio },
        {
          headers: {
            'Content-Type': 'application/json',
          },
          withCredentials: true,
        }
      );
      if (response.status === 200) {
        showToast('success', "Profile updated successfully!");
        onBack(); // Close edit sidebar on success
        
      }
    } catch (error) {
      setError('Failed to save profile');
      console.error('Error updating profile:', error);
    }
  };

  return (
    <SidebarContainer>
      <EditProfileContainer>
        <EditProfileHeader>
          <IconButton onClick={onBack} style={{marginRight: '10px'}}>
            <MdArrowBack />
          </IconButton>
          <Typography variant="h6">Edit Profile</Typography>
        </EditProfileHeader>

        {profile && (
          <>
            <ProfileDetails>
              <ProfileImage src={profile.photoURL} alt={profile.displayName} />
              <div>
                <Typography variant="h6" fontWeight="bold">
                  {profile.displayName}
                </Typography>

              </div>
              
            </ProfileDetails>
            <Typography variant="body2">
                  Here you can change your public profile. This will be visible to all other users, including non-users who download Safarnama QR codes to view apps, when you publish an experience. You can opt to leave this blank.
                </Typography>
            <ProfileForm onSubmit={handleSubmit}>
              <FormField>
                <TextField
                  label="Bio"
                  value={bio}
                  onChange={(e) => setBio(e.target.value)}
                  variant="standard"
                  multiline
                  rows={4}
                  fullWidth
                />
              </FormField>
              {error && <p className='error-message'>{error}</p>}
              <Button variant="contained" color="primary" type="submit">
                Save
              </Button>
            </ProfileForm>
          </>
        )}
      </EditProfileContainer>
    </SidebarContainer>
  );
};

export default UserEditProfile;
