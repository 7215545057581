import React from 'react';

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Link,
  Typography,
} from '@mui/material';

// Styled button using styled-components
// const OkButton = styled(Button)`
//   display: block;
//   padding: 10px 20px;
//   background-color: #4285f4;
//   color: white;
//   border-radius: 5px;
//   cursor: pointer;
//   margin-left: auto;
  
//   &:hover {
//     background-color: #357ae8;
//   }
// `;

// The TermsModal component using Material-UI Dialog
const TermsModal = ({ isOpen, onClose }) => {
  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      maxWidth="sm"
      fullWidth
      aria-labelledby="terms-dialog-title"
    >
      <DialogTitle id="terms-dialog-title">Terms & Privacy</DialogTitle>
      <DialogContent dividers>
        <Typography variant="body1" gutterBottom>
          <b>Authoring tool data protection notice</b>
        </Typography>
        <Typography variant="body" gutterBottom>
          <em>A: Logging in</em>
        </Typography><br/><br/>
        <Typography variant="body2" paragraph>
          You can log in with your Google account. Google may store some of your data, including personal data and data about your use of this app. See Google’s full privacy policy{' '}
          <Link href="https://policies.google.com/" target="_blank">here</Link>.
          University College Cork and University of Lancaster are not responsible for data collection by these third parties.
        </Typography>
        
        <Typography variant="body" gutterBottom>
          <em>B: Submitting experiences</em>
        </Typography>
        <ul>
          <li>
          <Typography variant="body2" paragraph> By creating an ‘experience’, you are submitting your material for use on the Ports, Past and Present app.</Typography>
          </li>
          <li>
          <Typography variant="body2" paragraph>The app serves to educate and inform tourists and communities on the cultural heritage of ports in Ireland and Wales.</Typography>
          </li>
          <li>
          <Typography variant="body2" paragraph> Your material could be used in project publicity and research as well as on the app. It may also be reused by third parties under the{' '}
            <Link href="https://creativecommons.org/licenses/by-nc-nd/4.0/" target="_blank">Creative Commons Attribution-NonCommercial-NoDerivatives 4.0 International (CC BY-NC-ND 4.0)</Link> License.
            If you wish to opt for a more open license, such as the Creative Commons Attribution 4.0 International (CC BY 4.0) License, please contact us.</Typography>
          </li>
        </ul>

        <Typography variant="body2" paragraph>
          University College Cork (UCC) is the designated project data controller. Aoife Dowling is the nominated contact; email her at{' '}
          <Link href="mailto:aoife.dowling@ucc.ie">aoife.dowling@ucc.ie</Link>.
        </Typography>
        <Typography variant="body2" paragraph>
          Lancaster University is the designated project data processor, and the authoring tool is hosted in the UK on a university server. Deborah Sutton is the nominated contact; email her on{' '}
          <Link href="mailto:d.sutton@lancaster.ac.uk">d.sutton@lancaster.ac.uk</Link>.
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} variant="contained">OK</Button>
      </DialogActions>
    </Dialog>
  );
};

export default TermsModal;
