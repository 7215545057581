import { useState } from 'react';

const useSidebar = () => {
  const [openMenu, setOpenMenu] = useState(null);
  const [isEditingProfile, setIsEditingProfile] = useState(false);
  const [isCreatingPlace, setIsCreatingPlace] = useState(false);
  const [isEditingPlace, setIsEditingPlace] = useState(false);
  const [isCreatingRoute, setIsCreatingRoute] = useState(false);
  const [isEditingRoute, setIsEditingRoute] = useState(false);
  const [showProjectsList, setShowProjectsList] = useState(false);
  const [showPlacesList, setShowPlacesList] = useState(false);
  const [showRoutesList, setShowRoutesList] = useState(false);
  const [isViewUsers, setIsViewUsers] = useState(false);
  const [isViewPublishedExperiences, setIsViewPublishedExperiences] = useState(false);
  const [isPublishDialogOpen, setIsPublishDialogOpen] = useState(false);
  const [isMediaDialogOpen, setIsMediaDialogOpen] = useState(false);
  const [selectedPlace, setSelectedPlace] = useState(null);
  const [selectedRoute, setSelectedRoute] = useState(null);

  const handleMenuClick = (menu) => {
    setOpenMenu(menu === openMenu ? null : menu);
  };

  return {
    openMenu,
    setOpenMenu,
    isEditingProfile,
    setIsEditingProfile,
    isCreatingPlace,
    setIsCreatingPlace,
    isEditingPlace,
    setIsEditingPlace,
    isCreatingRoute,
    setIsCreatingRoute,
    isEditingRoute,
    setIsEditingRoute,
    showProjectsList,
    setShowProjectsList,
    showPlacesList,
    setShowPlacesList,
    showRoutesList,
    setShowRoutesList,
    isViewUsers,
    setIsViewUsers,
    isViewPublishedExperiences,
    setIsViewPublishedExperiences,
    isPublishDialogOpen,
    setIsPublishDialogOpen,
    isMediaDialogOpen,
    setIsMediaDialogOpen,
    selectedPlace,
    setSelectedPlace,
    selectedRoute,
    setSelectedRoute,
    handleMenuClick,
  };
};

export default useSidebar;
