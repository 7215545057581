import React from 'react';

import logoImage from '../../assets/master-logo.png';
import LogoContainer from './styled/LogoContainer';

const Logo = () => {
  return (
    <LogoContainer>
      <img src={logoImage} alt="Logo" style={{ maxWidth: '100%', height: 'auto' }} />
    </LogoContainer>
  );
};

export default Logo;
