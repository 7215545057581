import React, { useState } from 'react';

import { FaGoogle } from 'react-icons/fa'; // Import the Google icon
import styled from 'styled-components';

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Link,
  Typography,
} from '@mui/material'; // Import Material-UI Dialog components

import BASE_URL from '../../config';
import TermsModal
  from './TermsModal'; // Assuming TermsModal is a separate component

const SignInButton = styled(Button)`
  display: flex;
  align-items: center;
  background-color: #4285f4 !important; /* Apply background color */
  color: white !important; /* Apply text color */
  padding: 10px 15px;
  justify-content: right;
  margin-left: auto;
  
  &:hover {
    background-color: #357ae8 !important; // Darker blue on hover
  }

  svg {
    margin-right: 8px; // Space between icon and text
  }
`;

const SignInModal = ({ isOpen, onClose }) => {
  const [isTermsOpen, setIsTermsOpen] = useState(false);

  const googleSignIn = () => {
    window.location.href = `${BASE_URL}/api/auth/google`; // Redirect to Google OAuth
  };

  const openTerms = () => {
    setIsTermsOpen(true); // Open the Terms modal
  };

  const closeTerms = () => {
    setIsTermsOpen(false); // Close the Terms modal
  };

  return (
    <>
      <Dialog open={isOpen} onClose={onClose} maxWidth="xs" fullWidth>
        <DialogTitle>Sign In</DialogTitle>
        <DialogContent>
          <Typography variant="body1">
            To get started, please sign in using Google. By signing in, you agree to our{' '}
            <Link href="#terms" onClick={openTerms}>Terms & Privacy Policy</Link>.
          </Typography>
          
        </DialogContent>
        <DialogActions>
        <SignInButton onClick={googleSignIn} variant="contained">
            <FaGoogle /> Sign in with Google
          </SignInButton>
        </DialogActions>
      </Dialog>
      <TermsModal isOpen={isTermsOpen} onClose={closeTerms} />
    </>
  );
};

export default SignInModal;
