import React, {
  useEffect,
  useState,
} from 'react';

import axios from 'axios';

import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';

import BASE_URL from '../../config';

const UsersList = ({ openViewUsersDialog, setOpenViewUsersDialog }) => {
    const [users, setUsers] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    const handleClose = () => {
        setOpenViewUsersDialog(false);
    };

    // Fetch users from API on component mount
    useEffect(() => {
        const fetchUsers = async () => {
        
        const storedUserData = sessionStorage.getItem("userData");
        const userData = storedUserData ? JSON.parse(storedUserData) : null;
        // console.log(userData);
        try {
            const response = await axios.get(`${BASE_URL}/api/admin/users`, {
            withCredentials: true,
            });
            setUsers(response.data);
        } catch (err) {
            setError('Failed to fetch users');
        } finally {
            setLoading(false);
        }
        };
    
        fetchUsers();
    }, []);
    

    return (
        <Dialog open={openViewUsersDialog} onClose={handleClose} fullWidth maxWidth="md">
        <DialogTitle>Users</DialogTitle>
        <DialogContent>
            {loading ? (
            <CircularProgress />
            ) : error ? (
            <p>{error}</p>
            ) : (
                <TableContainer component={Paper} style={{maxHeight:400}}>
                <Table stickyHeader>
                <TableHead>
                    <TableRow>
                    <TableCell>Avatar</TableCell>
                    <TableCell>Name</TableCell>
                    <TableCell>Created</TableCell>
                    <TableCell>ID</TableCell>
                </TableRow>
                </TableHead>
                <TableBody>
                    {users?.map((user) => (
                    <TableRow key={user.id}>
                        <TableCell>
                        {user.photoURL ? (
                            <img src={user.photoURL} alt={user.displayName} width={50} />
                        ) : (
                            'No Avatar'
                        )}
                        </TableCell>
                        <TableCell>{user.displayName}</TableCell>
                        <TableCell>{new Date(user.createdAt).toLocaleDateString()}</TableCell>
                        <TableCell>{user.facebookId || user.googleId}</TableCell>

                    </TableRow>
                    ))}
                </TableBody>
                </Table>
            </TableContainer>
            )}
        </DialogContent>
        <DialogActions>
            <Button onClick={handleClose}>Cancel</Button>
        </DialogActions>
        </Dialog>
    );
    };

    export default UsersList;
