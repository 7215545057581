import React from 'react';

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from '@mui/material';

const WelcomePopup = ({ open, onClose, onCreate, onOpen }) => {
  const styles = {
    buttonContainer: {
      marginTop: '20px',
      display: 'flex',
      justifyContent: 'flex-end',
    },
    buttonSpacing: {
      marginLeft: '10px',
    },
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="xs"
      fullWidth
      aria-labelledby="welcome-dialog-title"
    >
      <DialogTitle id="welcome-dialog-title">Welcome to Safarnama</DialogTitle>
      <DialogContent>
        <Typography variant="body1" gutterBottom>
          We recommend you use Google Chrome on a desktop to run this application.
        </Typography>
        <Typography variant="body1">
          To begin, create a new or open an existing experience.
        </Typography>
      </DialogContent>
      <DialogActions style={styles.buttonContainer}>
        <Button onClick={onCreate} variant="contained" color="primary">
          Create
        </Button>
        <Button onClick={onOpen} variant="outlined" style={styles.buttonSpacing}>
          Open
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default WelcomePopup;
