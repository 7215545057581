import React, { useState } from 'react';

import axios from 'axios';

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material';

import BASE_URL from '../../config';
import { showToast } from '../../utils/toast';

const ExportExperience = ({ isOpen, onClose, experienceId }) => {
  const [loading, setLoading] = useState(false);
  const handleExport = async () => {
    setLoading(true);
    // const storedOwnerId = sessionStorage.getItem("ownerId");

    try {
      const response = await axios.get(
        `${BASE_URL}/api/experience/${experienceId}/export`,
        {
          headers: {
            "Content-Type": "application/json",

          },
          withCredentials: true,
        }
      );

      if (response.status === 200) {
        // 

        const url = window.URL.createObjectURL(new Blob([response.data]));

        // Create a link element
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `experience_${experienceId}.zip`); // Set the file name

        // Append to the body
        document.body.appendChild(link);

        // Trigger the download
        link.click();

        // Clean up and remove the link
        link.remove();
        window.URL.revokeObjectURL(url);
        showToast('success', 'Experience export initiated successfully!');
        onClose();

      } else {
        console.log(response.data.error);
        setError(response?.data?.error?.message ? response?.data?.error?.message : "Error exporting experience"); // Display the error message
      }
    } catch (error) {
      console.log(error.response?.data?.error?.message ? error.response?.data?.error?.message : "Error exporting experience");
      setError(error.response?.data?.error?.message ? error.response?.data?.error?.message : "Error exporting experience");
    } finally {
      setLoading(false);

    }
  };

  const [error, setError] = useState(null);
  return (
    <Dialog open={isOpen} onClose={onClose}>
      <DialogTitle>Export Experience</DialogTitle>
      <DialogContent>
        {error && <p className="error-message">{error}</p>}
        <p>This will export all media items, icons, and a JSON representation of the experience.</p>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} variant="outlined" disabled={loading}>
          Cancel
        </Button>
        <Button onClick={handleExport} variant="contained" color="primary" disabled={loading}>
          {loading ? "Exporting..." : "Export"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ExportExperience;
