import 'filepond/dist/filepond.min.css';
import 'react-confirm-alert/src/react-confirm-alert.css';

import React, {
  useEffect,
  useState,
} from 'react';

import axios from 'axios';
import { confirmAlert } from 'react-confirm-alert';
import { FilePond } from 'react-filepond';
import {
  MdArrowBack,
  MdDelete,
} from 'react-icons/md';
import styled from 'styled-components';

import AddIcon from '@mui/icons-material/Add';
import {
  Avatar,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  IconButton,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material';

import BASE_URL from '../../config';
import { showToast } from '../../utils/toast';
import MediaLibrary from '../Media/MediaLibrary';
import DefaultPlaceIcon from './default_place_type.png';
import PlaceTypeList from './PlaceTypeList';

const FormContainer = styled.div`
  padding: 20px;
  font-family: 'Roboto Slab';

`;
const SidebarContainer = styled.div`
  width: ${(props) => (props.$isCollapsed ? "0" : "20%")};
  background: white;
  color: black;
  display: flex;
  flex-direction: column;
  transition: width 0.3s ease;

  @media (max-width: 768px) {
    width: ${(props) => (props.$isCollapsed ? "0" : "100%")};
  }

  @media (min-width: 769px) and (max-width: 1024px) {
    width: ${(props) => (props.$isCollapsed ? "0" : "50%")};
  }
`;
const Header = styled.div`
  display: flex;
  align-items: center;
  padding: 10px;
  background-color: lightgrey;
  margin-bottom: 10px;
`;


const Actions = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
`;


const CreatePlace = ({ onBack, coordinates, isSignedIn, userId, isEditing, placeData }) => {
  // console.log("placedata");
  // console.log(placeData);
  // console.log("coordinates");
  // console.log(coordinates);
  const [placeName, setPlaceName] = useState(placeData?.name || '');


  const [media, setMedia] = useState(placeData?.mediaItems || []);
  const [selectedMediaIds, setSelectedMediaIds] = useState(new Set(placeData?.mediaItems?.map(m => m._id) || []));
  // console.log(selectedMediaIds);
  const [description, setDescription] = useState(placeData?.description || '');

  const [open, setOpen] = useState(false);
  // const [placeName, setPlaceName] = useState('');
  const [selectedType, setSelectedType] = useState('');
  const [types, setTypes] = useState([]);

  // const [media, setMedia] = useState([]);
  // const [selectedMediaIds, setSelectedMediaIds] = useState(new Set());
  const [newTypeName, setNewTypeName] = useState('');
  const [error, setError] = useState('');
  const [isMediaDialogOpen, setIsMediaDialogOpen] = useState(false);

  const storedExperienceId = sessionStorage.getItem("experienceId");

  // const [description, setDescription] = useState('');
  // Example of fetching place types (mimicking an API call)
  useEffect(() => {

    handleGetTypes();
  }, []);
  // useEffect(() => {
  //   setMedia([...selectedMediaIds]); // Convert the Set to an Array
  // }, [selectedMediaIds]);

  const handleSelectMediaIds = (updatedMedia) => {
    const updatedMediaIds = new Set(updatedMedia.map(mediaItem => mediaItem._id)); // Create a Set of IDs
    setSelectedMediaIds(updatedMediaIds); // Update selected media IDs
    setMedia(updatedMedia); // Store full media objects in state
  };

  const openMediaDialog = () => {
    setIsMediaDialogOpen(true); // Open Publish dialog
  };
  const closeMediaDialog = () => {
    setIsMediaDialogOpen(false); // Close Publish dialog
  };
  const handleGetTypes = async () => {
    try {
      const response = await axios.get(
        `${BASE_URL}/api/place-types/mine`,
        {
          headers: {
            "Content-Type": "application/json",

          },
          withCredentials: true,
        }
      );

      if (response.status === 200) {
        setTypes(response.data);
        const initialSelectedType = isEditing && placeData?.type
          ? response.data.find(
            (type) => type.name === placeData.type.name && type.imageIconURL === placeData.type.imageIconURL
          )?._id
          : '';
        setSelectedType(initialSelectedType);
        //  console.log(response);
      }
    } catch (error) {
      //  console.log(error);
      console.log(error.response?.data?.error?.message ? error.response?.data?.error?.message : "Failed to fetch place types");
      //   setError(error.response?.data?.error?.message ? error.response?.data?.error?.message : "Failed to fetch place types");
    }
  }

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Check if coordinates are set
    if (!coordinates) {
      // alert('Please select a location on the map');
      showToast('warning', 'Please select a location on the map');
      return;
    }

    // Check if the place name is provided
    if (!placeName) {
      setError('Name is required');
      return;
    }

    // Construct the trigger zone based on your requirements
    const triggerZone = {
      type: 'circle', // or 'polygon', depending on your requirements
      lat: coordinates.lat, // Latitude from coordinates
      lng: coordinates.lng, // Longitude from coordinates
      colour: '#008080', // Example color
      radius: coordinates.radius || 800, // Default to 0 if radius is undefined
    };

    const selectedPlaceType = selectedType ? types.find(type => type._id === selectedType) : null;



    // Create the new place object
    const newPlace = {
      name: placeName,
      description,
      location: {
        type: 'Point',
        coordinates: [coordinates.lng, coordinates.lat], // [lng, lat] format
      },
      triggerZone, // Include triggerZone in the request
      ownerId: userId,
      experienceId: storedExperienceId,
      media,
      type: selectedPlaceType,
    };

    // console.log(newPlace); // Log the new place object for debugging

    try {
      const response = await axios.post(`${BASE_URL}/api/point-of-interest`, newPlace, {
        headers: {
          "Content-Type": "application/json",
        },
        withCredentials: true, // Correctly send credentials
      });

      // Check for successful response
      if (response.status !== 200) {
        throw new Error('Failed to create place');
      }

      const data = response.data; // Get data directly from the response
      // alert('Place created successfully'); // Notify user of success
      showToast('success', 'Place created successfully!');
      // console.log(data); // Log the data returned from the server
      onBack();

    } catch (error) {
      showToast('error', 'Failed to update place!');
      console.error("Error details:", error); // Log error details for debugging
      // alert(error.response?.data?.error || error.message); // Show a meaningful error message
    }
  };
  const handleEditPlace = async () => {

    try {
      const poiId = isEditing && placeData?.id;
      const triggerZone = {
        type: 'circle',
        lat: coordinates.lat,
        lng: coordinates.lng,
        colour: '#008080',
        radius: coordinates.radius || 800,
      };
      // console.log("on edit coordinates");
      // console.log(coordinates);

      const selectedPlaceType = selectedType ? types.find(type => type._id === selectedType) : null;

      const updatedPlaceData = {
        name: placeName,
        type: selectedPlaceType,
        description,
        location: {
          type: 'Point',
          coordinates: [coordinates.lng, coordinates.lat],
        },
        triggerZone,
        media,
        ownerId: userId,
        experienceId: storedExperienceId,
      };

      const response = await axios.put(`${BASE_URL}/api/point-of-interest/${poiId}`, updatedPlaceData, {
        headers: {
          'Content-Type': 'application/json',
        },
        withCredentials: true,
      });
      if (response.status !== 200) {
        throw new Error('Failed to update place');
      }
      showToast('success', 'Place updated successfully!');
      // alert('Place updated successfully');
      // return response.data;
      onBack();
    } catch (error) {
      showToast('error', 'Failed to update place!');
      console.error("Error updating place:", error);
      // alert(error.response?.data?.error || error.message);
    }
  };

  // Delete Place
  const handleDeleteClick = () => {
    confirmAlert({
      title: 'Confirm to delete',
      message: 'Are you sure you want to delete this?',
      buttons: [
        {
          label: 'Yes',
          onClick: () => handleDeletePlace()
        },
        {
          label: 'No',
          onClick: () => { } // Or any close action
        }
      ]
    });
  };
  const handleDeletePlace = async () => {
    const poiId = isEditing && placeData?.id;

    // if (window.confirm("Are you sure you want to delete this place?")) {
    try {
      const response = await axios.delete(`${BASE_URL}/api/point-of-interest/${poiId}`, {
        withCredentials: true,
      });
      if (response.status !== 200) {
        throw new Error('Failed to delete place');
      }
      // alert('Place deleted successfully');
      showToast('success', 'Place deleted successfully!');
      return response.data;
    } catch (error) {
      console.error("Error deleting place:", error);
      showToast('error', 'Failed to delete place!');
      // alert(error.response?.data?.error || error.message);
    }
    // }
  };
  const handleDeleteType = async (typeId) => {

    try {
      const response = await axios.delete(
        `${BASE_URL}/api/place-types/${typeId}`,
        {
          headers: {
            "Content-Type": "application/json",
          },
          withCredentials: true,
        }
      );
      if (response.status === 200) {
        handleGetTypes();
        setError("");
      } else {
        setError("Failed to remove types of place.");
      }
    } catch (err) {
      console.error(err);
      setError("Error removing types of place.");
    }
  };

  const handleAddNewType = async () => {
    if (newTypeName && media.length > 0) {
      try {
        const base64Media = await toBase64(media[0].file);  // Convert the file to base64

        const response = await axios.post(
          `${BASE_URL}/api/place-types`,
          {
            name: newTypeName,
            imageIconURL: base64Media,  // Pass the base64-encoded image
          },
          {
            headers: {
              "Content-Type": "application/json",
            },
            withCredentials: true,
          }
        );

        if (response.status === 200) {
          setTypes([...types, response.data]);
          //   setOpen(false);
          resetFields();
          // console.log(response);
          setError("");
          showToast('success', 'PlaceType created successfully!');

        } else {
          showToast('error', "Failed to create place types. Try to upload less sized image.");
          throw new Error(`Failed to create new type: ${response.statusText}`);
        }
      } catch (error) {
        // console.error('Failed to create new type:', error);
        showToast('error', "Failed to create place types. Try to upload less sized image.");
        // setError(error.message);
      }
    }
  };
  const resetFields = () => {
    setNewTypeName(''); // Clear the type name
    setMedia([]); // Clear the uploaded files
    setError(''); // Clear any existing error messages
  };
  const toBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file); // This will read the file as a base64 encoded string
      reader.onload = () => resolve(reader.result); // On success, resolve the promise with the result
      reader.onerror = (error) => reject(error); // On error, reject the promise
    });
  };

  return (
    <SidebarContainer>
      <FormContainer>
        <Header>
          <IconButton style={{ marginRight: '10px' }} onClick={onBack}>
            <MdArrowBack />
          </IconButton>
          <Typography variant="h6">{isEditing ? 'Edit' : 'Create'} Place</Typography>
        </Header>

        <form>
          <Typography variant="body2">
            {isEditing ? 'You can move this Place by dragging it on the map.' : 'Click the map to position your new Place'}
          </Typography>

          <Divider sx={{ marginY: 2 }} />

          <Typography variant="h6">Trigger Zone</Typography>
          <Typography variant="body2">
            When a user enters this area they will be notified about this Place.
          </Typography>

          <FormControl fullWidth sx={{ marginY: 2 }}>
            <TextField
              label="Name"
              value={placeName}
              onChange={(e) => setPlaceName(e.target.value)}
              required
              variant="standard"
            />
          </FormControl>


          <Box sx={{ display: 'flex', alignItems: 'flex-end', marginY: 2 }}>

            <FormControl fullWidth sx={{ flex: 1, marginRight: 2 }}>
              <InputLabel sx={{ marginX: -2 }}>Type</InputLabel>

              <Select
                value={selectedType}
                onChange={(e) => setSelectedType(e.target.value)}
                required
                variant="standard"
                renderValue={(selected) => {
                  const selectedTypeObject = types.find(type => type._id === selected);
                  return (
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                      {/* Display the icon if it exists, otherwise show a default icon */}
                      <Avatar
                        src={selectedTypeObject?.imageIconURL || DefaultPlaceIcon}
                        alt={selectedTypeObject?.name}
                        sx={{ width: 24, height: 24 }}
                      />
                      <span>{selectedTypeObject?.name}</span>
                    </Box>
                  );
                }}


              >

                <MenuItem disabled value="">
                  <em>Choose a place type</em>
                </MenuItem>

                {Array.isArray(types) && types.map((type, index) => (
                  <MenuItem
                    key={type._id ? type._id : index}
                    value={type._id}
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      paddingLeft: 2,
                      '&:hover': {
                        backgroundColor: '#f0f0f0', // Change the background on hover
                      }
                    }}
                  >
                    {/* Display the imageIconURL if it exists */}
                    <Avatar
                      src={type.imageIconURL || DefaultPlaceIcon}
                      alt={type.name}
                      sx={{ width: 24, height: 24, marginRight: 1 }}
                    />
                    <ListItemText primary={type.name} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>


            <IconButton onClick={() => { setOpen(true); resetFields(); }} sx={{ padding: 0, borderRadius: '50%  ' }} title="Add New Place Type">
              <AddIcon />
            </IconButton>
          </Box>

          <Divider sx={{ marginY: 2 }} />

          <Typography variant="h6">Media</Typography>
          <Button variant="contained" style={{ backgroundColor: '#e0e0e0', color: 'black', marginTop: '10px' }} onClick={openMediaDialog}>
            Select Media
          </Button>
          {Array.from(selectedMediaIds).length > 0 && (
            <p>
              ({Array.from(selectedMediaIds).length} files are selected)
            </p>
          )}
          <Divider sx={{ marginY: 2 }} />

          <Actions>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              disabled={!placeName || !selectedType}
              onClick={isEditing ? handleEditPlace : handleSubmit}
            >
              {isEditing ? 'Update' : 'Create'}
            </Button>
            {isEditing && (
              <Button
                variant="outlined"
                color="error"
                onClick={handleDeleteClick}
                startIcon={<MdDelete />}
              >
                Delete
              </Button>
            )}
          </Actions>
        </form>
      </FormContainer>
      {/* Modal for Adding New Place Type */}
      <Dialog open={open} onClose={() => setOpen(false)} maxWidth="sm" fullWidth>
        <DialogTitle>Add New Place Type</DialogTitle>
        <DialogContent>
          <p>Here you can create a new place type to assign to your places.</p>

          {/* Name Input for New Type */}
          <TextField
            label="Type Name"
            fullWidth
            value={newTypeName}
            onChange={(e) => setNewTypeName(e.target.value)}
            required
            margin="dense"
          />

          {/* File Upload Component for Icons */}
          <FilePond
            files={media}
            onupdatefiles={setMedia}
            allowMultiple={false}
            name="media"
            labelIdle="Drag & Drop your icon or <span class='filepond--label-action'>Browse</span>"
          />
          <PlaceTypeList types={types} handleDeleteType={handleDeleteType} />
          {/* Error Handling */}
          {error && <p style={{ color: 'red' }}>{error}</p>}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(false)}>Cancel</Button>
          <Button onClick={handleAddNewType} variant="contained" color="primary" disabled={!newTypeName}>
            Create
          </Button>

        </DialogActions>
      </Dialog>
      <MediaLibrary open={isMediaDialogOpen} isSignedIn={isSignedIn} onClose={closeMediaDialog} experienceId={storedExperienceId} parent={'places'} selectedMediaIds={Array.from(selectedMediaIds)} onSelectMediaIds={handleSelectMediaIds} />


    </SidebarContainer>
  );
};

export default CreatePlace;
