import styled from 'styled-components';

const ItemContainer = styled.div`
  padding: 15px 20px;
  cursor: pointer;
  color: black;
  border-bottom: 1px solid #e0e0e0;
  position: relative; 
  &:hover {
    background: #e8eaed;
  }
  overflow: visible;
`;

export default ItemContainer;