import React, {
  useEffect,
  useState,
} from 'react';

import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Tab,
  Tabs,
  Typography,
  useTheme,
} from '@mui/material';

import ProjectService from './ProjectService';
import ProjectViewDetails from './ProjectViewDetails';
import ProjectViewPublishedExperience from './ProjectViewPublishedExperience';
import ProjectViewUser from './ProjectViewUser';

const ProjectView = ({ open, handleClose, projectId }) => {
  const [project, setProject] = useState(null);
  const [tabIndex, setTabIndex] = useState(0);
  const theme = useTheme();

  useEffect(() => {
    if (projectId) {
      ProjectService.getById(projectId).then(setProject);
    }
  }, [projectId]);

  if (!project) return null;

  return (
    <Dialog open={open} onClose={handleClose} fullWidth maxWidth="md">
      <DialogTitle>
        <Typography variant="h6" fontWeight="bold" gutterBottom>
          {project.name}
        </Typography>
      </DialogTitle>
      <DialogContent dividers>
        <Box
          display="flex"
          justifyContent="center"
          borderBottom={1}
          borderColor="divider"
          mb={2}
          sx={{
            backgroundColor: theme.palette.background.paper,
            borderRadius: '8px',
            boxShadow: theme.shadows[2],
            padding: '8px',
          }}
        >
          <Tabs
            value={tabIndex}
            onChange={(e, newIndex) => setTabIndex(newIndex)}
            centered
            textColor="primary"
            indicatorColor="primary"
            TabIndicatorProps={{
              style: {
                height: '4px',
                borderRadius: '4px',
                backgroundColor: theme.palette.primary.main,
              },
            }}
          >
            <Tab
              label="Published Experiences"
              sx={{
                minWidth: 150,
                fontWeight: 'bold',
                textTransform: 'capitalize',
                color: theme.palette.text.primary,
                padding: '12px',
                borderRadius: '8px',
                '&.Mui-selected': {
                  color: theme.palette.primary.main,
                },
                '&:hover': {
                  backgroundColor: theme.palette.action.hover,
                },
              }}
            />
            <Tab
              label="Users"
              sx={{
                minWidth: 150,
                fontWeight: 'bold',
                textTransform: 'capitalize',
                color: theme.palette.text.primary,
                padding: '12px',
                borderRadius: '8px',
                '&.Mui-selected': {
                  color: theme.palette.primary.main,
                },
                '&:hover': {
                  backgroundColor: theme.palette.action.hover,
                },
              }}
            />
            <Tab
              label="Details"
              sx={{
                minWidth: 150,
                fontWeight: 'bold',
                textTransform: 'capitalize',
                color: theme.palette.text.primary,
                padding: '12px',
                borderRadius: '8px',
                '&.Mui-selected': {
                  color: theme.palette.primary.main,
                },
                '&:hover': {
                  backgroundColor: theme.palette.action.hover,
                },
              }}
            />
          </Tabs>
        </Box>
        <Box sx={{ mt: 2, p: 1 }}>
          {tabIndex === 0 && <ProjectViewPublishedExperience project={project} />}
          {tabIndex === 1 && <ProjectViewUser projectData={project} />}
          {tabIndex === 2 && <ProjectViewDetails project={project} />}
        </Box>
      </DialogContent>
      <DialogActions>
        <Button
          variant="outlined"
          color="primary"
          onClick={handleClose}
          sx={{ minWidth: 100 }}
        >
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ProjectView;
