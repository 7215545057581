import React, { useState } from 'react';

import {
  AdminPanelSettings,
  RemoveCircleOutline,
} from '@mui/icons-material';
import {
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
} from '@mui/material';

import ProjectService from './ProjectService';

const ProjectViewUser = ({ projectData }) => {
  const [project, setProject] = useState(projectData);

  const toggleAdmin = (member) => {
    const action = member.roles.includes('admin') ? 'removeRole' : 'setRole';
    ProjectService[action](project._id, member.userId, 'admin').then(updatedProject => {
      setProject(updatedProject);
    }).catch(error => {
      console.error('Error updating role:', error);
    });
  };

  return (
    <TableContainer component={Paper} style={{ maxHeight: 400 }}>
      <Table stickyHeader>
        <TableHead>
          <TableRow>
            <TableCell>User ID</TableCell>
            <TableCell>Name</TableCell>
            <TableCell>Roles</TableCell>
            <TableCell>Actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {project.members.map((member) => (
            <TableRow key={member.userId}>
              <TableCell>{member.userId}</TableCell>
              <TableCell>{member?.name}</TableCell>
              <TableCell>{member.roles.join(', ')}</TableCell>
              <TableCell>
                <Tooltip
                  title={member.roles.includes('admin') ? 'Remove Admin' : 'Make Admin'}
                  placement="top"
                >
                  <IconButton
                    onClick={() => toggleAdmin(member)}
                    color={member.roles.includes('admin') ? 'error' : 'primary'}
                    size="small"
                  >
                    {member.roles.includes('admin') ? (
                      <RemoveCircleOutline />
                    ) : (
                      <AdminPanelSettings />
                    )}
                  </IconButton>
                </Tooltip>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default ProjectViewUser;
