import 'react-toastify/dist/ReactToastify.css';

import { toast } from 'react-toastify';

export const showToast = (type, message) => {
  switch (type) {
    case 'success':
      toast.success(message, { position: 'top-right' });
      break;
    case 'error':
      toast.error(message, { position: 'top-right' });
      break;
    case 'info':
      toast.info(message, { position: 'top-right' });
      break;
    case 'warning':
      toast.warn(message, { position: 'top-right' });
      break;
    default:
      toast(message, { position: 'top-right' });
      break;
  }
};
