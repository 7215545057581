import React, {
  useEffect,
  useState,
} from 'react';

import {
  Star,
  StarBorder,
} from '@mui/icons-material';
import {
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
} from '@mui/material';

import AdminService from './AdminService';

const ProjectViewPublishedExperience = ({ project }) => {
  const [experiences, setExperiences] = useState([]);

  useEffect(() => {
    AdminService.getPublishedExperiences(project._id).then(setExperiences);
  }, [project._id]);

  const handleFeatureExperience = (exp) => {
    AdminService.setExperienceAsFeatured(exp._id, !exp.metaData.featured, [project.name]).then(() => {
      exp.metaData.featured = !exp.metaData.featured;
      setExperiences([...experiences]);
    });
  };

  return (
    <TableContainer component={Paper} style={{ maxHeight: 400 }}>
      <Table stickyHeader>
        <TableHead>
          <TableRow>
            <TableCell>Name</TableCell>
            <TableCell>Description</TableCell>
            <TableCell>Actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {experiences.map((exp) => (
            <TableRow key={exp._id}>
              <TableCell>{exp.name}</TableCell>
              <TableCell>{exp.description}</TableCell>
              <TableCell>
                <Tooltip
                  title={exp.metaData.featured ? 'Un-feature this experience' : 'Feature this experience'}
                  placement="top"
                >
                  <IconButton
                    onClick={() => handleFeatureExperience(exp)}
                    color={exp.metaData.featured ? 'warning' : 'primary'}
                    size="small"
                  >
                    {exp.metaData.featured ? (
                      <Star />
                    ) : (
                      <StarBorder />
                    )}
                  </IconButton>
                </Tooltip>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default ProjectViewPublishedExperience;
