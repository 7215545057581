import axios from 'axios';

import BASE_URL from '../../config';

const AdminService = {
  

  // Get a list of published experiences for a project (optional projectId parameter)
  getPublishedExperiences: async (projectId) => {
    try {
      const response = await axios.get(`${BASE_URL}/api/admin/published-experiences`, {
        params: projectId ? { projectId } : {},
        withCredentials: true,
      });
      return response.data;
    } catch (error) {
      console.error('Error fetching published experiences:', error);
      throw error;
    }
  },

  // Set or remove featured status for a published experience
  setExperienceAsFeatured: async (experienceId, feature = true, tags = []) => {
    try {
      if (feature) {
        const response = await axios.post(
          `${BASE_URL}/api/admin/published-experiences/${experienceId}/feature`,
          { tags },
          { withCredentials: true }
        );
        return response.data;
      } else {
        const response = await axios.delete(
          `${BASE_URL}/api/admin/published-experiences/${experienceId}/feature`,
          { withCredentials: true }
        );
        return response.data;
      }
    } catch (error) {
      console.error('Error setting experience as featured:', error);
      throw error;
    }
  },
};

export default AdminService;
