import React, {
  useEffect,
  useState,
} from 'react';

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  MenuItem,
  TextField,
} from '@mui/material';

const OpenExperience = ({ isOpen, onClose, onOpen, experiences, currentExperienceID, onBack }) => {
  const [selectedExperience, setSelectedExperience] = useState("");

  useEffect(() => {
    if (experiences.length > 0) {
      setSelectedExperience(currentExperienceID);
    }
  }, [experiences, currentExperienceID]);

  const handleOpen = () => {
    if (selectedExperience) {
      onOpen(selectedExperience);
    }
  };
  const storedExperienceId = sessionStorage.getItem("experienceId");
  const styles = {
    buttonContainer: {
      marginTop: "20px",
      display: "flex",
      justifyContent: "right",
    },
    buttonSpacing: {
      marginLeft: "10px",
    },
    formSetting: {
      width: "400px",
    },
    labelstyle: {
      marginTop: "10px",
      marginLeft: "0",
    },
  };
  return (
    <Dialog open={isOpen} onClose={onClose}>
      <DialogTitle>Open an Experience</DialogTitle>
      <DialogContent>
        <FormControl style={styles.formSetting}>
          {experiences.length > 0 ? (
            <TextField
              select
              label="Select Experience"
              name="experience"
              margin="normal"
              variant="standard"
              value={selectedExperience || ""}
              onChange={(e) => setSelectedExperience(e.target.value)}
            >
              {experiences.map((experience) => (
                <MenuItem key={experience._id} value={experience._id}>
                  {experience.name}
                </MenuItem>
              ))}
            </TextField>
          ) : (
            <p>No experiences available</p>
          )}
        </FormControl>
      </DialogContent>
      <DialogActions>
        {storedExperienceId && (<Button
          onClick={onClose}
          variant="outlined"
          style={styles.buttonSpacing}
        >
          Cancel
        </Button>
        )}
        {!storedExperienceId && (<Button
          onClick={onBack}
          variant="outlined"
          style={styles.buttonSpacing}
        >
          Create
        </Button>
        )}
        <Button
          onClick={handleOpen}
          variant="contained"
          color="primary"
          disabled={!selectedExperience}
        >
          Open
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default OpenExperience;
