import React, {
  useEffect,
  useState,
} from 'react';

import styled from 'styled-components';

// Styled component for the media item container
const MediaItemContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 10px;
  margin-bottom: 15px;
`;

// Styled component for the media image
const MediaImage = styled.img`
  width: 100px;
  height: 100px;
  object-fit: cover;
  margin-right: 20px;
`;

// Styled component for the media info
const MediaInfo = styled.div`
  display: flex;
  flex-direction: column;
`;

// Placeholder style for when thumbUrl is not available
const PlaceholderContainer = styled.div`
  width: 100px;
  height: 100px;
  background-color: #f0f0f0; // Light gray background
  display: flex;
  justify-content: center;  // Center horizontally
  align-items: center;      // Center vertically
  margin-right: 20px;
  color: #888;              // Gray text color
  text-align: center;       // Center text
  font-size: 14px;          // Optional: adjust font size for better visibility
`;

// MediaItem component with row layout
const MediaItem = ({ mediaItem }) => {
  const [thumbnail, setThumbnail] = useState(null);

  useEffect(() => {
    // console.log(mediaItem);
    if (mediaItem.path && mediaItem.mimetype.startsWith('image/')) {
      createThumbnail(mediaItem.path);
    }
  }, [mediaItem]);

  const createThumbnail = (url) => {
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');

    const img = new Image();
    img.crossOrigin = 'anonymous';
    img.src = url;
    img.onload = () => {
      const MAX_WIDTH = 100;
      const MAX_HEIGHT = 100;
      const aspectRatio = img.width / img.height;

      if (aspectRatio > 1) {
        canvas.width = MAX_WIDTH;
        canvas.height = MAX_WIDTH / aspectRatio;
      } else {
        canvas.height = MAX_HEIGHT;
        canvas.width = MAX_HEIGHT * aspectRatio;
      }

      ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
      setThumbnail(canvas.toDataURL('image/jpeg'));
    };
  };

  return (
    <MediaItemContainer>
      {thumbnail ? (
        // {mediaItem.thumbPath}
        <MediaImage src={thumbnail} alt={mediaItem.name} />
      ) : (
        <PlaceholderContainer>
          <span>No Image Available</span>
        </PlaceholderContainer>
      )}
      <MediaInfo>
        <h4>{mediaItem.name ? mediaItem.name : ""}</h4>
        <p>{mediaItem.description ? mediaItem.description : ""}</p>
        <p style={{ fontSize: '14px' }}>
          <b>Created:</b> {new Date(mediaItem.created_at).toLocaleString()}<br />
          <b>Edited:</b> {new Date(mediaItem.updated_at).toLocaleString()}<br />
          <b>Type:</b> {mediaItem.mimetype}
        </p>
      </MediaInfo>
    </MediaItemContainer>
  );
};

export default MediaItem;
