import 'react-quill/dist/quill.snow.css';

import React, {
  useEffect,
  useState,
} from 'react';

import axios from 'axios';
import ReactQuill from 'react-quill';

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material';

import BASE_URL from '../../config';
import { showToast } from '../../utils/toast';

const TextMediaComponent = ({ open, onClose, mediaItem, onRefresh, selectedMediaId}) => {
  const [text, setText] = useState('');

  useEffect(() => {
    // Fetch existing text if editing
    if (mediaItem && selectedMediaId) {
      axios.get(mediaItem.path, { responseType: 'text' })
        .then(response => {
          setText(response.data);
        })
        .catch(error => {
          console.error('Error fetching media:', error);
        });
    } else{
      setText('');
    }
  }, [mediaItem, selectedMediaId]);

  const handleSave = async () => {
    const formData = new FormData();
    const file = new File([text], 'text.html', { type: 'text/html' });
    formData.append('filepond', file);
    formData.append('description', mediaItem ? mediaItem.description : ''); // Use existing description if editing
    formData.append('expId', mediaItem ? mediaItem.expId : ''); // Use existing experience ID if editing

    try {
      if (mediaItem && selectedMediaId) {
        // Edit existing media
        await axios.put(`${BASE_URL}/api/media/files/${mediaItem._id}`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
          withCredentials: true,
        });
      } else {
        // Create new media
        await axios.post(`${BASE_URL}/api/media/process`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
          withCredentials: true,
        });
      }
      showToast('success', 'Media saved successfully!');
      // console.log('Media saved successfully');
      onRefresh(); // Call the refresh function passed from parent component
      onClose(); // Close the dialog after successful upload
    } catch (error) {
      console.error('Error saving media:', error);
      // alert('Media could not be saved: ' + error.message);
      showToast('error', 'Media could not be saved');
    }
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="xs" fullWidth>
      <DialogTitle>{mediaItem && selectedMediaId ? 'Edit' : 'Create'} Text</DialogTitle>
      <form>
        <DialogContent>
          <p>Type your text below, highlight text for formatting options</p>
          <ReactQuill theme="snow" value={text} onChange={setText} />
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>Cancel</Button>
          <Button
            variant="contained"
            color="primary"
            disabled={!text}
            onClick={handleSave}
          >
            Save
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default TextMediaComponent;
