// FeatureExperienceForm.js
import React, { useState } from 'react';

import axios from 'axios';

import {
  Autocomplete,
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from '@mui/material';

import BASE_URL from '../../config';
import { showToast } from '../../utils/toast';

const FeatureExperienceForm = ({ open, onClose, experience, fetchPublishedExperiences }) => {
  const [tags, setTags] = useState([]);
  const availableTags = ["Ports Past and Present", "Delhi", "Safarnama"]; // Replace with actual tags

  const handleAddTag = (event, newValue) => {
    setTags(newValue);
  };

  const handleSubmit = async (event) => {

    event.preventDefault();
    try {
      const response = await axios.post(`${BASE_URL}/api/admin/published-experiences/${experience._id}/feature`, {
        tags: tags,

      }, {
        headers: {
          "Content-Type": "application/json",
        },
        withCredentials: true, // Correctly send credentials
      });

      // Check for successful response
      if (!response.data._id) {
        throw new Error('Failed to create feature');
      }

      const data = response.data; // Get data directly from the response
      // alert('Feature created successfully'); // Notify user of success
      showToast('success', 'Feature created successfully!')
      // console.log(data); // Log the data returned from the server
      fetchPublishedExperiences();
      onClose();
    } catch (error) {
      // console.error("Error details:", error); // Log error details for debugging
      // alert(error.response?.data?.error || error.message); // Show a meaningful error message
      showToast('error', 'Failed to  create feature')
    }


  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
      <DialogTitle>Feature Experience</DialogTitle>
      <DialogContent>
        <p>Featuring an experience will display it in the list of featured experiences. Assign a tag to have it show in specific apps.</p>
        <Autocomplete
          multiple
          options={availableTags}
          value={tags}
          onChange={handleAddTag}
          renderTags={(value, getTagProps) =>
            value.map((option, index) => (
              <Chip key={index} label={option} {...getTagProps({ index })} />
            ))
          }
          renderInput={(params) => (
            <TextField {...params} variant="outlined" label="Tags" placeholder="Add a tag" />
          )}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button onClick={handleSubmit} color="primary">Feature</Button>
      </DialogActions>
    </Dialog>
  );
};

export default FeatureExperienceForm;
