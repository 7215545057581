import axios from 'axios';

import BASE_URL from '../../config';

const ProjectService = {
  getById: (projectId) => {
    return axios.get(`${BASE_URL}/api/project/${projectId}`, {
      withCredentials: true,
    }).then(response => response.data);
  },
  getList: () => {
    return axios.get(`${BASE_URL}/api/project`, {
      withCredentials: true,
    }).then(response => response.data);
  },

  // Add the setRole function
  setRole: (projectId, userId, role) => {
    return axios.put(`${BASE_URL}/api/project/${projectId}/member/${userId}/${role}`, null, {
      withCredentials: true,
    }).then(response => response.data);
  },

  // Add the removeRole function
  removeRole: (projectId, userId, role) => {
    return axios.delete(`${BASE_URL}/api/project/${projectId}/member/${userId}/${role}`, {
      withCredentials: true,
    }).then(response => response.data);
  },


editProjectById: (projectId, updateData) => {
  return axios.put(`${BASE_URL}/api/project/${projectId}`, updateData, {
    headers: { 'Content-Type': 'application/json' },
    withCredentials: true,
  }).then(response => response.data);
},
};

export default ProjectService;
