import React, {
  useEffect,
  useState,
} from 'react';

import axios from 'axios';

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from '@mui/material';

import BASE_URL from '../../config';
import { showToast } from '../../utils/toast';

const EditExperience = ({ isOpen, onClose, experienceId, experience, onSaveSuccess }) => {
  const [name, setName] = useState(experience?.name || "");
  const [description, setDescription] = useState(experience?.description || "");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (experience) {
      setName(experience.name || "");
      setDescription(experience.description || "");
    }
  }, [experience]);

  const handleSave = async () => {
    setLoading(true);
    const storedOwnerId = sessionStorage.getItem("ownerId"); // Get the owner ID from sessionStorage
    // const token = sessionStorage.getItem("token"); // Assuming the JWT is stored here

    try {
      const response = await axios.put(`${BASE_URL}/api/experience/${experienceId}`, {
        name,
        description,
        user: { _id: storedOwnerId } // Include the owner ID in the request body
      }, {
        headers: {
          "Content-Type": "application/json",
          //   "Authorization": `Bearer ${token}`, // Include the token in the headers
        },
        withCredentials: true // Include credentials in the request
      });

      // Handle success
      if (response.status === 200) {

        setName(response.data.name);
        setDescription(response.data.description);
        onSaveSuccess(response.data);
        showToast('success', 'Experience updated successfully!');
      } else {
        throw new Error(`Failed to edit experience: ${response.statusText}`);
      }
    } catch (error) {
      // Handle errors
      console.error("Error editing experience:", error);
      setError("Error editing experience");
    } finally {
      setLoading(false);
      //   onClose(); // Close modal after action
    }
  };


  return (
    <Dialog open={isOpen} onClose={onClose}>
      <DialogTitle>Edit Experience</DialogTitle>
      <DialogContent>
        {error && <p className="error-message">{error}</p>}
        <TextField
          label="Name"
          variant="standard"
          value={name}
          onChange={(e) => setName(e.target.value)}
          fullWidth
          margin="normal"
        />
        <TextField
          label="Description"
          variant="standard"
          value={description}
          onChange={(e) => setDescription(e.target.value)}
          fullWidth
          margin="normal"
          multiline
          rows={4}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} variant="outlined" disabled={loading}>
          Cancel
        </Button>
        <Button onClick={handleSave} variant="contained" color="primary" disabled={loading}>
          {loading ? "Saving..." : "Save"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EditExperience;
