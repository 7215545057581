import axios from 'axios';

import BASE_URL from '../../config';

const PUBLISH_URL = `${BASE_URL}/api/experience`;
const storedOwnerId = sessionStorage.getItem("ownerId");

export const publishExperience = async (id) => {
  const response = await axios.post(`${PUBLISH_URL}/${id}/publish`, {user: { _id: storedOwnerId },}, {
    headers: { 'Content-Type': 'application/json' },
    withCredentials: true,
  });
  return response.data;
};

export const unpublishExperience = async (id) => {
  const response = await axios.post(`${PUBLISH_URL}/${id}/unpublish`, {user: { _id: storedOwnerId },}, {
    headers: { 'Content-Type': 'application/json' },
    withCredentials: true,
  });
  return response.data;
};

export const getLatestPublishedSnapshot = async (id) => {
  const response = await axios.get(`${PUBLISH_URL}/${id}/snapshot`);
  return response.data;
};
