import React, {
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';

import UndoIcon from '@mui/icons-material/Undo';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  IconButton,
} from '@mui/material';

import { useColor } from '../Routes/ColorContext';
import {
  MapWrapper,
  SliderWrapper,
} from './styled/MapStyles.js';

const MapContainer = ({ onMapClick, onMapRouteClick, initialCoordinates, mode, initialPoints, viewExperiences }) => {
  const mapRef = useRef(null);
  const markerRef = useRef(null);
  const markerRefs = useRef([]);
  const circleRefs = useRef([]);
  const circleRef = useRef(null);
  const polylineRef = useRef(null);
  const polylineRefs = useRef([]);
  const { polylineColor } = useColor();
  const [radius, setRadius] = useState(initialCoordinates?.radius || 800);
  const [latLng, setLatLng] = useState(initialCoordinates || null);
  const [editStart, setEditStart] = useState(false);
  const [routePoints, setRoutePoints] = useState(initialPoints || []);
  const [prevLatLng, setPrevLatLng] = useState(initialCoordinates || null);
  const [prevRadius, setPrevRadius] = useState(initialCoordinates?.radius || 800);
  const [googleMapsLoaded, setGoogleMapsLoaded] = useState(false);
  const [localRoutePoints, setLocalRoutePoints] = useState([]);
  const [selectedMedia, setSelectedMedia] = useState(null);
  const mediaRefs = useRef([]);
  const handleRadiusChange = useCallback((e) => {
    setRadius(parseInt(e.target.value, 10));

  }, []);

  const loadGoogleMapsAPI = () => {
    if (window.google && window.google.maps) {
      setGoogleMapsLoaded(true); // API is already loaded
      return;
    }

    if (!document.querySelector('script[src="https://maps.googleapis.com/maps/api/js?key=' + process.env.REACT_APP_GOOGLE_MAPS_API_KEY + '&libraries=places&loading=async&callback=initMap"]')) {

      const script = document.createElement('script');
      script.src = `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}&libraries=places&loading=async&callback=initMap`;

      script.async = true;
      script.defer = true;
      script.onload = () => setGoogleMapsLoaded(true);
      document.body.appendChild(script);
    }
  };

  useEffect(() => {
    // Load the Google Maps API only if it hasn't been loaded yet
    if (!googleMapsLoaded) {
      loadGoogleMapsAPI();
    }
  }, [googleMapsLoaded]);
  window.handleMediaClickFromInfo = (index) => {
    const media = mediaRefs.current[index];
    handleMediaClick(media);
  };
  const handleMediaClick = (media) => {
    if (media.mimetype.startsWith('image/')) {
      setSelectedMedia(media.path); // Show image in dialog
    } else {
      window.open(media.path, '_blank'); // Open non-image files in new tab
    }
  };
  const handleCloseMediaDialog = () => {
    setSelectedMedia(null);
  };
  useEffect(() => {

    const initializeMap = (lat, lng) => {
      if (!googleMapsLoaded || !window.google) return;
      try {
        const mapInstance = new window.google.maps.Map(document.getElementById('map'), {
          center: { lat, lng },
          zoom: 14,
        });
        mapRef.current = mapInstance;
        if (initialPoints && initialPoints?.length && mode === 'EditRoute') {
          setRoutePoints(initialPoints);

        }
        if (viewExperiences && viewExperiences.length && mode != 'CreateRoute' && mode != 'CreatePlace' && mode != 'EditPlace' && mode != 'EditRoute') {
          // console.log(viewExperiences);
          viewExperiences.forEach((experience) => {
            // console.log(experience);
            experience.pointOfInterests.forEach((poi) => {
              // console.log('poi',poi);
              // poi.triggerzone.forEach((zone) => {
              // Draw marker for each POI trigger zone
              const { lat, lng, radius, colour } = poi.triggerZone;

              const position = { lat, lng };
              const circle = new window.google.maps.Circle({
                center: position,
                radius,
                map: mapRef.current,
                fillColor: colour,
                fillOpacity: 0.35,
                strokeColor: colour,
                strokeOpacity: 0.8,
                clickable: true, // Add interactive property if needed
              });

              const marker = new window.google.maps.Marker({
                position,
                map: mapRef.current,
              });

              // Add hover highlight for marker
              marker.addListener('mouseover', () => {
                marker.setIcon({
                  url: "https://maps.google.com/mapfiles/ms/icons/blue-dot.png",
                });
              });

              marker.addListener('mouseout', () => {
                marker.setIcon(null); // Reset to default icon
              });


              circle.addListener('click', () => {
                let mediaContent = '';
                poi.media.forEach((media, index) => {
                  mediaRefs.current = poi.media;
                  if (media.mimetype.startsWith('image/')) {
                    mediaContent += `
                      <div key="${index}" style="display: inline-block; margin: 4px;">
                        <img
                          src="${media.path}"
                          alt="media-${index}"
                          style="width: 40px; height: 40px; cursor: pointer;"
                          onclick="window.handleMediaClickFromInfo(${index})"
                        />
                      </div>
                    `;
                  } else if (media.mimetype === 'application/pdf') {
                    mediaContent += `
                      <div key="${index}" style="display: inline-block; margin: 4px;">
                        <button onclick="window.handleMediaClickFromInfo(${index})">
                          <img src="https://www.gstatic.com/images/icons/material/system/1x/description_black_24dp.png" style="width: 40px; height: 40px;" />
                        </button>
                      </div>
                    `;
                  } else {
                    mediaContent += `
                      <div key="${index}" style="display: inline-block; margin: 4px;">
                        <button onclick="window.handleMediaClickFromInfo(${index})">
                          <img src="https://upload.wikimedia.org/wikipedia/commons/thumb/0/08/Generic_file_icon.svg/256px-Generic_file_icon.svg.png" style="width: 40px; height: 40px;" />
                        </button>
                      </div>
                    `;
                  }
                });

                const infoContent = `
                <div style="max-width: 300px;">
                  <strong>${poi.name}</strong><br/>
                  <hr/><br/>
                  ${poi.type.imageIconURL ? `<img src="${poi.type.imageIconURL}" alt="type-icon" style="width: 30px; height: 30px;" />` : ''}${' '}${poi.type.name}
                  <br/><br/>
                  Media Items:
                  <div style="display: flex; flex-wrap: wrap;">
                    ${mediaContent}
                  </div>
                </div>
              `;
                const infoWindow = new window.google.maps.InfoWindow({
                  content: infoContent,
                });
                infoWindow.setPosition(position);
                infoWindow.open(mapRef.current);
              });

              // Push references to keep track for future updates
              markerRefs.current.push(marker);
              circleRefs.current.push(circle);
              // });
            });

            // Loop through all routes in the experience
            experience.routes.forEach((route) => {
              const { colour, geo } = route;

              // Extract coordinates from geo data
              const routeCoordinates = geo.coordinates.map(coord => ({ lat: coord[1], lng: coord[0] }));

              // Create Polyline for the route
              const polyline = new window.google.maps.Polyline({
                path: routeCoordinates,
                strokeColor: colour,
                strokeOpacity: 0.8,
                strokeWeight: 3,
                map: mapRef.current,
              });

              // Add click listener to show a tooltip with route name and description
              polyline.addListener('click', () => {
                const infoWindow = new window.google.maps.InfoWindow({
                  content: `<div style="max-width:'300px;'"><strong>${route.name}</strong><br/><hr/><br/><br/>${route.description}</div>`,
                });

                const middlePoint = routeCoordinates[Math.floor(routeCoordinates.length / 2)];
                infoWindow.setPosition(middlePoint);
                infoWindow.open(mapRef.current);
              });

              // Store polyline reference for future updates
              polylineRefs.current.push(polyline);
              if (routeCoordinates.length > 0) {
                // Start Marker (first point)
                const startPosition = routeCoordinates[0];
                const startMarker = new window.google.maps.Marker({
                  position: startPosition,
                  map: mapRef.current,
                  title: "Start",
                  icon: "https://maps.google.com/mapfiles/ms/icons/green-dot.png", // Green for start
                });

                // Finish Marker (last point)
                const finishPosition = routeCoordinates[routeCoordinates.length - 1];
                const finishMarker = new window.google.maps.Marker({
                  position: finishPosition,
                  map: mapRef.current,
                  title: "Finish",
                  icon: "https://maps.google.com/mapfiles/ms/icons/red-dot.png", // Red for finish
                });
              }
            });
          });
        }
        if (routePoints?.length && (mode === 'CreateRoute' || mode === 'EditRoute')) {
          // drawPolyline(routePoints,polylineColor);
          // polylineRef.current.setPath(routePoints);
          polylineRef.current = new window.google.maps.Polyline({
            path: routePoints,
            map: mapRef.current,
            strokeColor: polylineColor || '#1976d2',
            strokeOpacity: 0.8,
            strokeWeight: 3,
          });

          routePoints.map((point) => {
            const marker = new window.google.maps.Marker({
              position: point,
              map: mapRef.current,
            });
            markerRefs.current.push(marker);
          });

        }

        if (initialCoordinates) {
          // Set up the marker and circle for place creation
          const { lat, lng, radius } = initialCoordinates;
          setLatLng({ lat, lng });
          setRadius(radius);
          if (markerRef.current) markerRef.current.setMap(null);
          markerRef.current = new window.google.maps.Marker({
            position: { lat, lng },
            map: mapInstance,
          });
          if (circleRef.current) circleRef.current.setMap(null);
          circleRef.current = new window.google.maps.Circle({
            center: { lat, lng },
            radius,
            map: mapInstance,
            fillColor: '#008080',
            fillOpacity: 0.35,
            strokeColor: '#008080',
            strokeOpacity: 0.8,
          });
        }

        const mapClickListener = mapInstance.addListener('click', (mapsMouseEvent) => {
          // setEditStart(true);
          const latLng = mapsMouseEvent.latLng;
          const point = { lat: latLng.lat(), lng: latLng.lng() };

          if (mode === 'CreatePlace' || mode === 'EditPlace') {
            // Handle place creation mode
            setLatLng(point);
            if (markerRef.current) markerRef.current.setMap(null);
            markerRef.current = new window.google.maps.Marker({
              position: latLng,
              map: mapInstance,
            });
            if (circleRef.current) circleRef.current.setMap(null);
            circleRef.current = new window.google.maps.Circle({
              center: latLng,
              radius,
              map: mapInstance,
              fillColor: '#008080',
              fillOpacity: 0.35,
              strokeColor: '#008080',
              strokeOpacity: 0.8,
            });
            onMapClick({ lat: latLng.lat(), lng: latLng.lng(), radius, initialCoordinates });
          } else if (mode === 'CreateRoute' || mode === 'EditRoute') {
            // Handle route creation mode
            setRoutePoints((prevPoints) => {
              const newPoints = [...prevPoints, point];
              drawPolyline(newPoints, polylineColor);

              newPoints.forEach((point) => {
                new window.google.maps.Marker({
                  position: point, // Correct: point is a single LatLngLiteral
                  map: mapInstance,
                });
              });

              const marker = new window.google.maps.Marker({
                position: point,
                map: mapInstance,
              });
              markerRefs.current.push(marker);
              // onMapRouteClick && onMapRouteClick(newPoints);
              return newPoints;
            });
          }
        });
        return () => {
          window.google.maps.event.removeListener(mapClickListener);
        };
      }
      catch (error) {
        console.log("Error on map initialize..", error);
      }
    };


    // Initialize map based on initial coordinates or geolocation
    if (initialCoordinates) {
      initializeMap(initialCoordinates.lat, initialCoordinates.lng);
    } else if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => initializeMap(position.coords.latitude, position.coords.longitude),
        () => initializeMap(-34.397, 150.644)
      );
    } else {
      initializeMap(-34.397, 150.644);
    }

  }, [googleMapsLoaded, initialCoordinates, onMapClick, mode, polylineColor, initialPoints]);

  const drawPolyline = (points, polylineColor) => {

    polylineRef.current = new window.google.maps.Polyline({
      path: points,
      map: mapRef.current,
      strokeColor: polylineColor || '#1976d2',
      strokeOpacity: 0.8,
      strokeWeight: 3,
    });
    // if (!polylineRef.current) {
    //   polylineRef.current = new window.google.maps.Polyline({
    //     path: points,
    //     map: mapRef.current,
    //     strokeColor: polylineColor || '#1976d2',
    //     strokeOpacity: 0.8,
    //     strokeWeight: 3,
    //   });
    // } else {
    //   alert("Asdas");
    //   polylineRef.current.setPath(points);
    // }
  };

  useEffect(() => {
    if (routePoints.length && (mode === 'CreateRoute' || mode === 'EditRoute')) {
      drawPolyline(routePoints, polylineColor);
      routePoints.forEach((point) => {
        const marker = new window.google.maps.Marker({
          position: point,
          map: mapRef.current,
        });
        markerRefs.current.push(marker);
      });
    }
  }, [routePoints, polylineColor, mode]);

  useEffect(() => {
    // Update circle radius when it changes
    if (latLng && circleRef.current && (mode === 'CreatePlace' || mode === 'EditPlace')) {
      circleRef.current.setRadius(radius);
      if (
        (!prevLatLng || latLng.lat !== prevLatLng.lat || latLng.lng !== prevLatLng.lng) ||
        radius !== prevRadius
      ) {
        setPrevLatLng(latLng);
        setPrevRadius(radius);
        onMapClick({ ...latLng, radius, initialCoordinates });
      }
    }
  }, [radius, latLng, onMapClick, mode]);
  const handleUndo = (event) => {
    event.preventDefault();

    if (routePoints.length > 1) {
      const updatedPoints = routePoints.slice(0, -1);
      setRoutePoints(updatedPoints);

      // Remove all markers from the map
      markerRefs.current.forEach((marker) => {
        marker.setMap(null);
      });

      // Clear markerRefs and repopulate based on the updated points
      markerRefs.current = updatedPoints.map((point) => {
        const newMarker = new window.google.maps.Marker({
          position: point,
          map: mapRef.current,
        });
        return newMarker;
      });

      if (polylineRef.current) polylineRef.current.setMap(null);
      // Redraw the polyline with updated points
      drawPolyline(updatedPoints, polylineColor);
      // onMapRouteClick(updatedPoints);
    }
  };
  const havePointsChanged = (initialPoints, routePoints) => {
    // Check if lengths are different
    if (initialPoints.length !== routePoints.length) {
      return true;
    }

    // Check if any pair of lat/lng values is different
    for (let i = 0; i < initialPoints.length; i++) {
      if (
        initialPoints[i].lat !== routePoints[i].lat ||
        initialPoints[i].lng !== routePoints[i].lng
      ) {
        return true; // Points have changed
      }
    }

    return false; // Points are the same
  };
  useEffect(() => {

    // Call `onMapRouteClick` with final points when needed
    if (mode === 'EditRoute') {
      if (havePointsChanged(initialPoints, routePoints)) {
        onMapRouteClick(routePoints, routePoints);
      }
      else {
        onMapRouteClick(routePoints, initialPoints);
      }
    }
    if (mode === 'CreateRoute') {
      onMapRouteClick(routePoints, []);
    }
  }, [routePoints, onMapRouteClick, polylineColor]);
  return (
    <>
      <MapWrapper id="map" />

      <SliderWrapper>

        {((mode === 'CreatePlace' || mode === 'EditPlace') && initialCoordinates) && (
          <>
            <span>Radius: {radius}m</span>
            <input
              type="range"
              min="100"
              max="5000"
              value={radius}
              onChange={handleRadiusChange}
            />
          </>
        )}

        {(mode === 'CreateRoute' || mode === 'EditRoute') && routePoints.length > 0 && (
          // <UndoButton onClick={handleUndo}>Undo Last Point</UndoButton>
          <IconButton onClick={handleUndo} color="primary" aria-label="undo last point" style={{ right: '0px' }}>
            <UndoIcon />
          </IconButton>
        )}
      </SliderWrapper>
      <Dialog open={!!selectedMedia} onClose={handleCloseMediaDialog} maxWidth="sm" fullWidth>
        <DialogContent>
          {selectedMedia && (
            <img src={selectedMedia} alt="Selected Media" style={{ width: '100%' }} />
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseMediaDialog} variant="outlined">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default MapContainer;
