import React from 'react';

import useSubMenu from './hooks/useSubMenu';
import {
  IconWrapper,
  SubMenuContainer,
  SubMenuItem,
} from './styled/SubMenuStyled';

const SubMenu = ({ items = [], icons = [], subItemActions, onClose, isFirstMenu = false }) => {
  const { openUp, subMenuRef } = useSubMenu(isFirstMenu);
  
  if (!Array.isArray(items) || !Array.isArray(icons)) {
    console.error('Invalid items or icons array');
    return null;
  }

  return (
    <SubMenuContainer ref={subMenuRef} $openUp={openUp} $isFirstMenu={isFirstMenu}>
      {items.map((item, index) => (
        <SubMenuItem
          key={index}
          onClick={() => {
            if (subItemActions[index]) subItemActions[index](); // Call the corresponding action
            if (onClose) onClose(); // Close the submenu
          }}
        >
          <IconWrapper>
            <span style={{ marginRight: '10px', fontSize: '18px' }}>{icons[index]}</span>
            {item}
          </IconWrapper>
        </SubMenuItem>
      ))}
    </SubMenuContainer>
  );
};

export default SubMenu;
