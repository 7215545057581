import React, {
  useEffect,
  useState,
} from 'react';

import { QRCodeSVG } from 'qrcode.react';

import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material';

import {
  getLatestPublishedSnapshot,
  publishExperience,
  unpublishExperience,
} from './PublishService';

const PublishExperience = ({ open, onClose, experienceId }) => {
  const [latestSnapshot, setLatestSnapshot] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  useEffect(() => {
    const fetchLatestSnapshot = async () => {
      if (open && experienceId) {
        setLoading(true);
        try {
          const snapshot = await getLatestPublishedSnapshot(experienceId);

          setLatestSnapshot(snapshot);
          setError('');
        } catch (err) {
          // console.error('Error fetching snapshot:', err);
          if (err.response.status == 404) {
            setError('Not yet Published!');
          }
          else {
            setError('Failed to load snapshot');
          }
        } finally {
          setLoading(false);
        }
      }
    };

    fetchLatestSnapshot();
  }, [open, experienceId]);

  const handlePublish = async () => {
    setLoading(true);
    try {
      const response = await publishExperience(experienceId);
      setLatestSnapshot(response);
    } catch (err) {
      // console.error('Error publishing experience:', err);
      setError('Failed to publish experience');
    } finally {
      setLoading(false);
    }
  };

  const handleUnpublish = async () => {
    setLoading(true);
    try {
      await unpublishExperience(experienceId);
      setLatestSnapshot(null);
    } catch (err) {
      console.error('Error unpublishing experience:', err);
      setError('Failed to unpublish experience');
    } finally {
      setLoading(false);
    }
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Publish Experience</DialogTitle>
      <DialogContent>
        {loading ? (
          <CircularProgress />
        ) : (
          <>
            {error && <p style={{ color: 'red' }}>{error}</p>}
            {!latestSnapshot ? (
              <p>Publishing your experience will make it publicly visible. Users will be able to download a playable experience.</p>
            ) : (
              <>
                <p>
                  Visit <a href={latestSnapshot.metaData.shortLink} target="_blank" rel="noopener noreferrer">{latestSnapshot.metaData.shortLink}</a>
                </p>
                <QRCodeSVG value={latestSnapshot.metaData.shortLink} size={192} />
                <p>Version <b>{latestSnapshot.metaData.version}</b> published at <b>{new Date(latestSnapshot.metaData.created_at).toLocaleString()}</b></p>
              </>
            )}
          </>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} variant="outlined">Close</Button>
        {!latestSnapshot ? (
          <Button onClick={handlePublish} variant="contained" color="primary">Publish</Button>
        ) : (
          <Button onClick={handleUnpublish} variant="contained" color="secondary">Unpublish</Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default PublishExperience;
