import React, {
  useEffect,
  useState,
} from 'react';

import axios from 'axios';

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';

import BASE_URL from '../../config';
import ProjectView from './ProjectView';

const ProjectViewAll = ({ openProjectsDialog, setOpenProjectsDialog }) => {
  const [projects, setProjects] = useState([]);
  const [selectedProjectId, setSelectedProjectId] = useState(null);
  const [openProjectTabsDialog, setOpenProjectTabsDialog] = useState(false);
  const currentUserId = sessionStorage.getItem('ownerId');

  useEffect(() => {
    // Fetch projects from API
    const fetchProjects = async () => {
      try {
        const response = await axios.get(`${BASE_URL}/api/project/mine`, {
          headers: { 'Content-Type': 'application/json' },
          withCredentials: true,
        });
        if (response.status === 200) {
          // console.log(response.data);
          setProjects(response.data);
        } else {
          console.error('Error fetching projects');
        }
      } catch (error) {
        console.error('Error in fetching projects:', error);
        // setError("Error fetching collaborators");
      }
    };

    fetchProjects();
  }, []);

  const isAdmin = (project) => {
    return project.members.some(
      (member) => member.userId === currentUserId && member.roles.includes('admin')
    );
  };

  const handleManageProject = (projectId) => {
    // console.log('Selected Project ID:', projectId); // Debugging log
    setSelectedProjectId(projectId);
    // setOpenProjectsDialog(false); // Close the projects list dialog
    setOpenProjectTabsDialog(true); // Open the project tabs dialog
  };

  const handleCloseProjectTabsDialog = () => {
    setOpenProjectTabsDialog(false); // Close the project tabs dialog
    setOpenProjectsDialog(true); // Reopen the projects list dialog
  };

  return (
    <div>
      {/* Projects List Dialog */}
      <Dialog open={openProjectsDialog} onClose={() => setOpenProjectsDialog(false)} fullWidth maxWidth="md">
        <DialogTitle>Projects</DialogTitle>
        <DialogContent>
          <TableContainer component={Paper} style={{ maxHeight: 400 }}>
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell>Name</TableCell>
                  <TableCell>Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {projects.map((project) => (
                  <TableRow key={project._id}>
                    <TableCell>{project.name}</TableCell>
                    <TableCell>
                      <Button
                        disabled={!isAdmin(project)}
                        variant="contained"
                        onClick={() => handleManageProject(project._id)}
                      >
                        Manage
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenProjectsDialog(false)} variant="outlined">
            Close
          </Button>
        </DialogActions>
      </Dialog>

      {openProjectTabsDialog && (
        <ProjectView
          open={openProjectTabsDialog}
          handleClose={handleCloseProjectTabsDialog}
          projectId={selectedProjectId}
        />
      )}
    </div>
  );
};

export default ProjectViewAll;
