import React, {
  createContext,
  useContext,
  useState,
} from 'react';

const ColorContext = createContext();

export const ColorProvider = ({ children }) => {
  const [polylineColor, setPolylineColor] = useState('#1976d2');

  return (
    <ColorContext.Provider value={{ polylineColor, setPolylineColor }}>
      {children}
    </ColorContext.Provider>
  );
};

export const useColor = () => useContext(ColorContext);
