import styled from 'styled-components';

export const MapWrapper = styled.div`
  height: calc(100vh - 50px);
  width: 100%;
  position: relative;
  @media (max-width: 768px) {
    height: 100vh;
  }
  @media (min-width: 769px) and (max-width: 1024px) {
    height: 100vh;
  }
`;

export const SliderWrapper = styled.div`
  position: absolute;
  bottom: 25px;
  right: 50px;
  transform: translateX(-50%);
  background: rgba(255, 255, 255, 0.8);
  padding: 3px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  gap: 8px;
`;
export const UndoButton = styled.button`
  // position: absolute;
  // right: 10px;
  background: #ffffff;
  border: 1px solid #cccccc;
  border-radius: 4px;
  padding: 5px;
  cursor: pointer;
`;
