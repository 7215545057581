// src/components/Header.js
import React from 'react';

import { FaBars } from 'react-icons/fa';
import styled from 'styled-components';

// Styled component for the header container
const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 10px;
  background-color: #008080;
  position: abolute;
  top: 0;
  left: 0;
  z-index: 500;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  height:30px;
`;

// Collapse button styling
const CollapseButton = styled.button`
  background: none;
  border: none;
  color: #FFFFFF;
  font-size: 1.5em;
  cursor: pointer;
  margin-right: 20px; // Space between the button and the title
`;

// Title styling
const Title = styled.h1`
  margin: 2px;
  font-size: 1.50em;
  text-align: left;
  color: #FFFFFF;
`;

const Header = ({ title, toggleSidebar }) => {
  return (
    <HeaderContainer>
      <CollapseButton onClick={toggleSidebar}>
        <FaBars />
      </CollapseButton>
      <Title>{title}</Title>
    </HeaderContainer>
  );
};

export default Header;
