import React, {
  useEffect,
  useState,
} from 'react';

import axios from 'axios';

import {
  Box,
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@mui/material';

import BASE_URL from '../../config';

const CreateExperience = ({ open, onClose, onSubmit, onBack }) => {
  const [projects, setProjects] = useState([]);
  const [loading, setLoading] = useState(false);
  const storedExperienceId = sessionStorage.getItem("experienceId");
  const styles = {

    buttonSpacing: {
      marginLeft: "10px",
    },
  };
  useEffect(() => {
    fetchProjects();
  }, []);

  const fetchProjects = async () => {
    setLoading(true);
    try {
      const response = await axios.get(`${BASE_URL}/api/project/mine`, {
        headers: { 'Content-Type': 'application/json' },
        withCredentials: true,
      });
      if (response.status === 200) {
        setProjects(response.data);
      } else {
        console.error("Error fetching projects");
      }
    } catch (error) {
      console.error("Error in fetching projects:", error);
    } finally {
      setLoading(false);
    }
  };

  const [form, setForm] = useState({
    name: "",
    description: "",
    projects: [],
  });

  const resetForm = () => {
    setForm({
      name: "",
      description: "",
      projects: [],
    });
  };

  useEffect(() => {
    if (open) {
      resetForm();
    }
  }, [open]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setForm({ ...form, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit(form);
    onClose();
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
      <DialogTitle>Create an Experience</DialogTitle>
      <form onSubmit={handleSubmit} style={{ width: "100%" }}>
        <DialogContent>
          <TextField
            label="Name your experience"
            name="name"
            fullWidth
            margin="normal"
            variant="standard"
            value={form.name}
            onChange={handleChange}
          />
          <TextField
            label="Description"
            name="description"
            fullWidth
            margin="normal"
            variant="standard"
            multiline
            rows={4}
            value={form.description}
            onChange={handleChange}
          />
          <FormControl fullWidth margin="normal" variant="standard">
            <InputLabel>Choose Projects (optional)</InputLabel>
            <Select
              multiple
              name="projects"
              value={form.projects}
              onChange={handleChange}
              renderValue={(selected) => (
                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                  {selected.map((id) => {
                    const project = projects.find((p) => p._id === id);
                    return <Chip key={id} label={project?.name || ''} />;
                  })}
                </Box>
              )}
              MenuProps={{
                PaperProps: { style: { maxHeight: 180 } },
                anchorOrigin: {
                  vertical: 'bottom',
                  horizontal: 'left',
                },
                transformOrigin: {
                  vertical: 'top',
                  horizontal: 'left',
                },
              }}
              disabled={loading || projects.length === 0}
            >
              {loading ? (
                <MenuItem disabled>Loading projects...</MenuItem>
              ) : projects.length > 0 ? (
                projects.map((project) => (
                  <MenuItem key={project._id} value={project._id}>
                    {project.name}
                  </MenuItem>
                ))
              ) : (
                <MenuItem disabled>No projects available</MenuItem>
              )}
            </Select>
          </FormControl>
        </DialogContent>
        <DialogActions>
          {!storedExperienceId && (<Button
            onClick={onBack}
            variant="outlined"
            style={styles.buttonSpacing}
          >
            Open
          </Button>
          )}
          <Button
            type="submit"
            variant="contained"
            color="primary"
            disabled={!form.name || !form.description}
          >
            Create
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default CreateExperience;
