import 'react-toastify/dist/ReactToastify.css';

import React, {
  useEffect,
  useState,
} from 'react';

import axios from 'axios';
import { ToastContainer } from 'react-toastify';

import { ThemeProvider } from '@mui/material/styles';

import SignInModal from './components/Auth/SigninModal';
import WelcomePopup from './components/Auth/WelcomePopup';
import CloneExperience from './components/Experience/CloneExperience';
import Collaborators from './components/Experience/Collaborators';
import CreateExperience from './components/Experience/CreateExperience';
import EditExperience from './components/Experience/EditExperience';
import ExportExperience from './components/Experience/ExportExperience';
import OpenExperience from './components/Experience/OpenExperience';
import Header from './components/Header/Header';
import MapContainer from './components/Map/MapContainer';
import { ColorProvider } from './components/Routes/ColorContext';
import Sidebar from './components/Sidebar/Sidebar';
import BASE_URL from './config';
import {
  Content,
  Layout,
  theme,
} from './styled/AppStyled';
import { showToast } from './utils/toast';

const App = () => {
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [isSignedIn, setIsSignedIn] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  // const [polylineColor, setPolylineColor] = useState('#1976d2');
  const [isWelcomeOpen, setIsWelcomeOpen] = useState(false);
  const [isCreateOpen, setIsCreateOpen] = useState(false);
  const [projects, setProjects] = useState([]);
  const [isOpenExperienceOpen, setIsOpenExperienceOpen] = useState(false);
  const [experiences, setExperiences] = useState([]);
  const [viewExperiences, setViewExperiences] = useState([]);
  const [isCloneModalOpen, setCloneModalOpen] = useState(false);
  const [isExportModalOpen, setExportModalOpen] = useState(false);
  const [isCollaboratorsModalOpen, setCollaboratorsModalOpen] = useState(false);
  const [isEditModalOpen, setEditModalOpen] = useState(false);
  // const [coordinates, setCoordinates] = useState(null);
  const [locationData, setLocationData] = useState(null);
  const [routePoints, setRoutePoints] = useState([]);
  const [mode, setMode] = useState(null);
  // const [routeData, setRouteData] = useState(null);
  const [currentExperience, setCurrentExperience] = useState({
    name: "", // Default title
    _id: null,
    desc: "",
    ownerId: "",
  });
  const toggleSidebar = () => {
    setIsCollapsed(!isCollapsed);
  };

  const storedOwnerId = sessionStorage.getItem("ownerId");
  useEffect(() => {

    const fetchProfile = async () => {
      try {
        const response = await axios.get(`${BASE_URL}/api/user`, {
          withCredentials: true,
        });
        // console.log(response.data.roles)
        let userData = response.data;
        sessionStorage.setItem("isUserAdmin", JSON.stringify(userData.roles?.includes('admin')));
        sessionStorage.setItem("userData", JSON.stringify(userData));
        // setProfile(response.data);
        // setBio(response.data.bio);
      } catch (error) {
        console.error('Error fetching profile:', error);
      }
    };
    if (isSignedIn) {
      fetchProfile();
    }
  }, [storedOwnerId, isSignedIn]);

  useEffect(() => {
    const storedExperienceName = sessionStorage.getItem("experienceName");
    const storedExperienceId = sessionStorage.getItem("experienceId");
    const storedExperienceDesc = sessionStorage.getItem("experienceDesc");
    const storedOwnerId = sessionStorage.getItem("ownerId");


    if (storedExperienceName && storedExperienceId) {
      setCurrentExperience({
        name: storedExperienceName,
        _id: storedExperienceId,
        desc: storedExperienceDesc || '',
        ownerId: storedOwnerId || null,
      });

      setIsWelcomeOpen(false);
      if (!storedExperienceName && !storedExperienceId) {
        setIsWelcomeOpen(true);
      }
    }

    const checkSession = async () => {
      const response = await fetch(`${BASE_URL}/api/auth/check-session`, {
        credentials: "include",
      });

      if (response.ok) {
        const data = await response.json();
        // console.log("data...");
        // console.log(data);
        setIsSignedIn(data.isAuthenticated);
        setIsModalOpen(!data.isAuthenticated);
        if (!storedExperienceName) {
          setIsWelcomeOpen(data.isAuthenticated);
        }
      }
    };

    checkSession();
  }, []);

  useEffect(() => {

    if (isSignedIn) {
      fetchExperiences();
    }
  }, [isSignedIn]);

  const fetchExperiences = async () => {
    try {
      const response = await fetch(`${BASE_URL}/api/experiences/mine`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
        credentials: "include",
      });

      if (!response.ok) {
        throw new Error(`Failed to fetch experiences: ${response.statusText}`);
      }

      const contentType = response.headers.get("Content-Type");

      // Check if the response is JSON
      if (contentType && contentType.includes("application/json")) {
        const responseData = await response.json();

        // Check if the 'data' field exists and has length
        if (responseData && responseData.data && responseData.data.length > 0) {
          setExperiences(responseData.data);  // Set the experiences from the 'data' field
          // console.log(responseData.data);
          const allExperiences = responseData.data;
          const storedExperienceId = sessionStorage.getItem("experienceId");
          if (storedExperienceId) {
            // Filter the experiences to match the stored experienceId
            const matchedExperiences = allExperiences.filter(
              (exp) => exp._id === storedExperienceId
            );

            // Update the viewExperiences state with the matched experiences
            setViewExperiences(matchedExperiences);
            // console.log(matchedExperiences);
          }

        } else {
          console.warn("No experiences available for this user.", responseData);
        }
      } else {
        console.error("Unexpected response format:", contentType);
      }
    } catch (error) {
      console.error("Error fetching experiences:", error);
    }
  };

  const handleCreateNew = () => {
    setIsCreateOpen(true);
    setIsWelcomeOpen(false);
    setProjects([]);

    // console.log("Is Create Open:", isCreateOpen);
  };

  const handleOpenExperience = (experienceId) => {
    const selectedExperience = experiences.find((exp) => exp._id === experienceId);
    sessionStorage.setItem("experienceName", selectedExperience.name);
    sessionStorage.setItem("experienceId", selectedExperience._id);
    sessionStorage.setItem("experienceDesc", selectedExperience.description);
    sessionStorage.setItem("ownerId", selectedExperience.ownerId);
    setCurrentExperience({ name: selectedExperience.name, _id: selectedExperience._id, desc: selectedExperience.description, ownerId: selectedExperience.ownerId });
    setIsOpenExperienceOpen(false);
    setIsWelcomeOpen(false);
    fetchExperiences();
    // alert(experienceId);
  };

  const handleUpdateExperience = (data) => {

    sessionStorage.setItem("experienceName", data.name);
    sessionStorage.setItem("experienceId", data._id);
    sessionStorage.setItem("experienceDesc", data.description);
    sessionStorage.setItem("ownerId", data.ownerId);
    setCurrentExperience({ name: data.name, _id: data._id, desc: data.description, ownerId: data.ownerId });
    setCloneModalOpen(false);
    setEditModalOpen(false);

  };

  const handleCloseWelcome = () => {
    const storedExperienceId = sessionStorage.getItem("experienceId");
    if (storedExperienceId) {
      setIsWelcomeOpen(false);
    }
    else {
      setIsWelcomeOpen(true);
      setIsOpenExperienceOpen(false);
      setIsCreateOpen(false);
    }
  };
  const handleCloseCreateExpereience = () => {
    const storedExperienceId = sessionStorage.getItem("experienceId");
    if (storedExperienceId) {
      setIsCreateOpen(false);


    }
    else {
      setIsCreateOpen(true);
    }
  };
  const handleBackCreateExpereience = () => {
    const storedExperienceId = sessionStorage.getItem("experienceId");
    setIsOpenExperienceOpen(true);
    setIsCreateOpen(false);

  };
  const handleBackOpenExpereience = () => {

    setIsOpenExperienceOpen(false);
    setIsCreateOpen(true);

  };
  const handleCloseOpenExpereience = () => {
    const storedExperienceId = sessionStorage.getItem("experienceId");
    if (storedExperienceId) {
      setIsOpenExperienceOpen(false);

    }
    else {
      setIsOpenExperienceOpen(true);
    }
  };


  const handleSubmitCreate = async (formData) => {
    try {
      const response = await fetch(`${BASE_URL}/api/experience`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        credentials: "include",
        body: JSON.stringify(formData),
      });

      if (!response.ok) {
        throw new Error("Failed to create experience");
      }

      const data = await response.json();
      fetchExperiences();
      // Store the experience name and _id in session storage
      sessionStorage.setItem("experienceName", data.name);
      sessionStorage.setItem("experienceId", data._id);
      sessionStorage.setItem("experienceDesc", data.description);
      sessionStorage.setItem("ownerId", data.ownerId);
      showToast('success', 'Experience created successfully!');
      // console.log("Experience created successfully:", data);
      setIsCreateOpen(false);
      // Update the state to reflect the new experience
      setCurrentExperience({ name: data.name, _id: data._id, desc: data.description, ownerId: data.ownerId });
    } catch (error) {
      console.error("Error creating experience:", error);
    }
  };

  const handleMapClick = ({ lat, lng, radius, initialCoordinates }) => {

    if (mode && mode === 'CreatePlace') {
      setLocationData({ lat, lng, radius });

      if (initialCoordinates) {
        handleSelectedPlaceCoordinates({ lat, lng, radius, initialCoordinates });
      }
      // console.log('Location:', lat, lng, 'Radius:', radius);
    }
    if (mode && mode === 'EditPlace') {
      if (initialCoordinates) {
        handleSelectedPlaceCoordinates({ lat, lng, radius, initialCoordinates });
      }
      // console.log('Location:', lat, lng, 'Radius:', radius);
    }
  };

  const handleRouteMapClick = (points, initialPoints) => {
    if (mode && mode === 'CreateRoute') {
      setRoutePoints(points);
      if (initialPoints.length == 0) {
        handleSelectedRoutePoints(points);
      }
      // console.log('createpoints:');
      // console.log(points);
    }
    if (mode && mode === 'EditRoute') {

      if (initialPoints) {
        handleSelectedRoutePoints(initialPoints);
      }

    }
  };

  const handleSelectedPlaceCoordinates = (coordinates) => {
    setLocationData(coordinates);
    // console.log('coordinates:', coordinates.lat, coordinates.lng, 'Radius:', coordinates.radius);
  };

  const handleSelectedRoutePoints = (points) => {
    setRoutePoints(points);
    // console.log('setRoutePoints(points):', points);
  };

  return (
    <>
      <ToastContainer />
      <ThemeProvider theme={theme}>

        {isModalOpen && (
          <SignInModal isOpen={isModalOpen} />
        )}
        {isSignedIn && (
          <>
            <WelcomePopup
              open={isWelcomeOpen}
              onClose={handleCloseWelcome}
              onCreate={handleCreateNew}
              onOpen={() => { setIsWelcomeOpen(false); setIsOpenExperienceOpen(true) }}
            />

            <CreateExperience
              open={isCreateOpen}
              onClose={handleCloseCreateExpereience}
              onBack={handleBackCreateExpereience}
              projects={projects}
              onSubmit={handleSubmitCreate}
            />

            <OpenExperience
              isOpen={isOpenExperienceOpen}
              onBack={handleBackOpenExpereience}
              onClose={handleCloseOpenExpereience}
              onOpen={handleOpenExperience}
              experiences={experiences}
              currentExperienceID={currentExperience._id}
            />
            <CloneExperience
              isOpen={isCloneModalOpen}
              onClose={() => setCloneModalOpen(false)}
              experienceId={currentExperience ? currentExperience._id : null}
              onCloneSuccess={(data) => handleUpdateExperience(data)}
            />

            {/* Export Experience Modal */}
            <ExportExperience
              isOpen={isExportModalOpen}
              onClose={() => setExportModalOpen(false)}
              experienceId={currentExperience ? currentExperience._id : null}
              onExport={() => console.log("Exporting...")}
              isExporting={false}
            />

            {/* Collaborators Modal */}
            <Collaborators
              isOpen={isCollaboratorsModalOpen}
              onClose={() => setCollaboratorsModalOpen(false)}
              experienceId={currentExperience ? currentExperience._id : null}
              experienceName={currentExperience ? currentExperience.name : ''}
              onAddCollaboratorSuccess={(userId) => {
                // console.log("Added collaborator:", userId);
                // showToast('success', "Added collaborator:"+ userId);
                // setCollaboratorsModalOpen(false);
              }}

            />

            {/* Edit Experience Modal */}
            <EditExperience
              isOpen={isEditModalOpen}
              onClose={() => setEditModalOpen(false)}
              experienceId={currentExperience ? currentExperience._id : null}
              experience={{
                id: currentExperience ? currentExperience._id : null,
                name: currentExperience ? currentExperience.name : "",
                description: currentExperience ? currentExperience.desc : "",
              }}
              onSaveSuccess={(data) => handleUpdateExperience(data)}
            />
          </>
        )}
        <ColorProvider>
          <Layout>

            <Sidebar
              isCollapsed={isCollapsed}
              onCreateExperience={handleCreateNew}
              onOpenExperience={() => setIsOpenExperienceOpen(true)}
              onCollborator={() => setCollaboratorsModalOpen(true)}
              onEditExperience={() => setEditModalOpen(true)}
              onCloneExperience={() => setCloneModalOpen(true)}
              onExportExperience={() => setExportModalOpen(true)}
              coordinates={locationData}
              isSignedIn={isSignedIn}
              routePoints={routePoints}
              onSetSelectedPlaceCoordinates={handleSelectedPlaceCoordinates}
              onSetSelectedRoutePoints={handleSelectedRoutePoints}
              mode={mode}
              setMode={setMode}
            // handleCreateRouteSubmit = {handleCreateRouteSubmit}
            />
            <Content>
              <Header
                title={currentExperience.name}
                toggleSidebar={toggleSidebar}
              />
              <MapContainer mode={mode} viewExperiences={viewExperiences} onMapClick={handleMapClick} onMapRouteClick={handleRouteMapClick} initialCoordinates={locationData} initialPoints={routePoints} />
            </Content>
          </Layout>
        </ColorProvider>

      </ThemeProvider>
    </>

  );
};

export default App;
