import {
  useEffect,
  useRef,
  useState,
} from 'react';

const useSubMenu = (isFirstMenu) => {
  const [openUp, setOpenUp] = useState(false);
  const subMenuRef = useRef(null);

  useEffect(() => {
    const checkSubMenuPosition = () => {
      if (subMenuRef.current) {
        const { bottom } = subMenuRef.current.getBoundingClientRect();
        const viewportHeight = window.innerHeight;

        if (bottom > viewportHeight && !isFirstMenu) {
          setOpenUp(true);
        } else {
          setOpenUp(false);
        }
      }
    };

    checkSubMenuPosition();
    window.addEventListener('resize', checkSubMenuPosition);

    return () => {
      window.removeEventListener('resize', checkSubMenuPosition);
    };
  }, [isFirstMenu]);

  return { openUp, subMenuRef };
};

export default useSubMenu;
