import React, {
  useEffect,
  useState,
} from 'react';

import axios from 'axios';

import {
  Delete,
  Edit,
} from '@mui/icons-material';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@mui/material';

import BASE_URL from '../../config';
import { showToast } from '../../utils/toast';

const RoutesList = ({ openRoutesDialog, setOpenRoutesDialog, setIsEditingRoute, setSelectedRoute, onEditRoute }) => {
  const [routes, setRoutes] = useState([]);

  useEffect(() => {
    const fetchRoutes = async () => {
      try {
        const response = await axios.get(`${BASE_URL}/api/route`, {
          headers: { 'Content-Type': 'application/json' },
          withCredentials: true,
        });

        if (response.status === 200 && Array.isArray(response.data)) {
          setRoutes(response.data);
          // console.log("rooutesssssssss");
          // console.log(response.data);
        } else {
          console.error("Unexpected response format or empty routes array");
          setRoutes([]);
        }
      } catch (error) {
        console.error("Error fetching routes:", error);
        setRoutes([]);
      }
    };

    if (openRoutesDialog) {
      fetchRoutes();
    }
  }, [openRoutesDialog]);

  const handleEditRoute = (route) => {
    setSelectedRoute(route);
    setIsEditingRoute(true);
    setOpenRoutesDialog(false);
    onEditRoute(route);
  };


  const handleDeleteRoute = async (id) => {


    // if (window.confirm("Are you sure you want to delete this place?")) {
    try {
      const response = await axios.delete(`${BASE_URL}/api/route/${id}`, {
        withCredentials: true,
      });
      if (response.status !== 200) {

        throw new Error('Failed to delete route');
      }
      setRoutes((prevRoutes) => prevRoutes.filter((route) => route._id !== id));
      // alert('Place deleted successfully');
      showToast('success', 'Place deleted successfully!');
      return response.data;
    } catch (error) {
      console.error("Error deleting route:", error);
      // alert(error.response?.data?.error || error.message);
    }
    // }
  };

  return (
    <Dialog open={openRoutesDialog} onClose={() => setOpenRoutesDialog(false)} fullWidth maxWidth="md">
      <DialogTitle>Routes</DialogTitle>
      <DialogContent>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Length (number of points)</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {routes.map((route, index) => (
              <TableRow key={index}>
                <TableCell>{route?.name || "Unnamed Route"}</TableCell>
                <TableCell>{route?.geo?.coordinates?.length}</TableCell>
                <TableCell>
                  <IconButton color="primary" onClick={() => handleEditRoute(route)}>
                    <Edit />
                  </IconButton>
                  <IconButton color="secondary" onClick={() => handleDeleteRoute(route._id)}>
                    <Delete />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setOpenRoutesDialog(false)} variant="outlined">
          Cancel
        </Button>
        {/* <Button variant="contained" onClick={() => (window.location.href = '/route/create')}>
          Create
        </Button> */}
      </DialogActions>
    </Dialog>
  );
};

export default RoutesList;
