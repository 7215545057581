// src/components/MediaDialog.js
import React, {
  useEffect,
  useState,
} from 'react';

import axios from 'axios';
import { useForm } from 'react-hook-form';

import {
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  Select,
} from '@mui/material';

import BASE_URL from '../../config';
import MediaItem from './MediaItem';
import TextMediaComponent from './TextMediaComponent';
import UploadMedia from './UploadMedia';

const MediaLibrary = ({ open, onClose, isSignedIn, experienceId, parent, selectedMediaIds, onSelectMediaIds }) => {
  const { register, setValue, watch } = useForm({
    defaultValues: {
      experience: 'all',
      type: 'All',
      sortBy: 'Date Added',
    }
  });

  const experience = watch("experience");
  const type = watch("type");
  const sortBy = watch("sortBy");

  const [mediaItems, setMediaItems] = useState([]);
  const [loading, setLoading] = useState(true);
  const [filteredMedia, setFilteredMedia] = useState([]);
  const [selecting, setSelecting] = useState(false);
  // const [selectedMediaIds, setSelectedMediaIds] = useState(new Set());
  const [selectedMediaId, setSelectedMediaId] = useState(null);
  const mimeTypesMap = {
    Images: ["image/jpeg", "image/png"],
    Videos: ["video/mp4"],
    Text: ["text/plain", "text/html"],
    Audio: ["audio/mpeg", "audio/wav"],
    PDFs: ["application/pdf"],
  };
  const [experienceOptions, setExperienceOptions] = useState([{ name: "All", _id: "all" }]);
  const mediaTypes = ["All", "Images", "Videos", "Text", "Audio", "PDFs"];
  const sortOptions = ["Date Added", "Date Edited"];
  const [uploadDialogOpen, setUploadDialogOpen] = useState(false);
  const [isTextDialogOpen, setIsTextDialogOpen] = useState(false);
  const [mediaItem, setMediaItem] = useState([]); // Replace with actual mediaItem if editing
  const [mediaDescription, setMediaDescription] = useState('');
  // const experienceId = sessionStorage.getItem("experienceId");
  const handleCreateTextClick = () => {
    setIsTextDialogOpen(true);
  }
  const selectedMediaSet = new Set(selectedMediaIds);
  const handleMultiSelectToggle = (mediaItem) => {
    const updatedMediaIds = new Set(selectedMediaIds);

    // If mediaItem is already selected, remove it; otherwise, add it
    if (updatedMediaIds.has(mediaItem._id)) {
      updatedMediaIds.delete(mediaItem._id);
    } else {
      updatedMediaIds.add(mediaItem._id);
    }

    // Pass the full media object to the parent
    onSelectMediaIds(Array.from(updatedMediaIds).map(id => mediaItems.find(item => item._id === id)));
  };

  const handleCloseDialog = () => {
    setIsTextDialogOpen(false);
    setSelecting(false);
    setSelectedMediaId(null);
    setMediaItem(null);
  };

  const handleSaveText = (text) => {
    // console.log('Saved Text:', text);
    setMediaDescription(text);
    // Handle save logic here, e.g., send to API or update state
    setIsTextDialogOpen(false);
  };
  const handleOpenUploadDialog = () => {
    setUploadDialogOpen(true);
  };

  const handleCloseUploadDialog = () => {
    setUploadDialogOpen(false);
  };

  useEffect(() => {
    onFilter();
  }, [experience, type, sortBy]);

  useEffect(() => {

    if (isSignedIn) {
      fetchExperiences();
      fetchMedia();
    }
  }, [isSignedIn]);

  const fetchExperiences = async () => {
    try {
      const response = await fetch(`${BASE_URL}/api/experiences/mine`, {
        method: "GET",
        headers: { "Content-Type": "application/json" },
        credentials: "include",
      });

      if (!response.ok) throw new Error(`Failed to fetch experiences: ${response.statusText}`);

      const contentType = response.headers.get("Content-Type");
      if (contentType && contentType.includes("application/json")) {
        const responseData = await response.json();
        if (responseData?.data?.length > 0) {
          setExperienceOptions([
            { name: "All", _id: "all" },  // "All" option for filtering all experiences
            ...responseData.data.map(exp => ({ name: exp.name, _id: exp._id }))
          ]);
        }
      }
    } catch (error) {
      console.error("Error fetching experiences:", error);
    }
  };

  const fetchMedia = async () => {
    setLoading(true);
    try {
      const response = await axios.get(`${BASE_URL}/api/media/mine`, {
        headers: { 'Content-Type': 'application/json' },
        withCredentials: true,
      });
      if (response.status === 200) {

        setMediaItems(response.data);
        // setFilteredMedia(response.data);
        let mediaData = response.data;

        // Apply experience filter
        if (experience !== "all") {
          mediaData = mediaData.filter((item) => item.experienceId === experience);
        }

        // Apply media type filter
        if (type !== "All") {
          const allowedMimeTypes = mimeTypesMap[type] || [];
          mediaData = mediaData.filter((item) => allowedMimeTypes.includes(item.mimetype));
        }

        // Apply sort option
        if (sortBy === "Date Added") {
          mediaData.sort((a, b) => new Date(b.dateAdded) - new Date(a.dateAdded));
        } else if (sortBy === "Date Edited") {
          mediaData.sort((a, b) => new Date(b.dateEdited) - new Date(a.dateEdited));
        }

        setFilteredMedia(mediaData);

        // console.log(response.data);
      } else {
        console.error("Error fetching Media Library");
      }

    } catch (error) {
      console.error('Failed to fetch media:', error);
    } finally {
      setLoading(false);
    }
  };
  const onFilter = () => {
    let filtered = mediaItems;

    // Filter by experience (using the first item in associatedExperiences array)
    if (experience !== 'all') {
      // alert(experience);
      filtered = filtered.filter(item =>
        item.associatedExperiences?.includes(experience)
      );
    }

    // Filter by media type
    if (type !== 'All') {
      const allowedMimeTypes = mimeTypesMap[type] || [];
      filtered = filtered.filter(item => allowedMimeTypes.includes(item.mimetype));
    }

    // Sort based on the selected option
    if (sortBy === 'Date Added') {
      filtered.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
    } else if (sortBy === 'Date Edited') {
      filtered.sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt));
    }
    // else if (sortBy === 'Name') {
    //   filtered.sort((a, b) => a.name.localeCompare(b.name));
    // }

    setFilteredMedia(filtered);
  };



  const handleMediaSelect = (mediaItem) => {
    // setSelectedMediaIds((prev) => {
    //   const newSelected = new Set(prev);
    //   if (newSelected.has(mediaId)) {
    //     newSelected.delete(mediaId);
    //   } else {
    //     newSelected.add(mediaId);
    //   }
    //   return newSelected;
    // });
    // console.log(mediaItem);
    setSelectedMediaId((prev) => (prev === mediaItem._id ? null : mediaItem._id));
    setMediaItem(mediaItem);
  };

  const handleDelete = async (id) => {
    try {
      await axios.delete(`${BASE_URL}/api/media/files/${id}`, {
        headers: { 'Content-Type': 'application/json' },
        withCredentials: true,
      });
      const response = await axios.get(`${BASE_URL}/api/media/mine`, {
        headers: { 'Content-Type': 'application/json' },
        withCredentials: true,
      });
      if (response.status === 200) {

        setMediaItems(response.data);
        setFilteredMedia(response.data);

      } else {
        console.error("Error fetching Media Library after deletion");
      }
    } catch (error) {
      console.error('Delete failed:', error);
    }
  };

  const handleClose = () => {
    setSelecting(false);
    setSelectedMediaId(null);
    setMediaItem(null);
    // setSelectedMediaIds(new Set());
    onClose();
  };

  return (
    <Dialog open={open} onClose={handleClose} maxWidth={false}
      sx={{
        '& .MuiDialog-paper': {
          width: '50%',
          maxWidth: 'none',
        },
      }}>
      <DialogTitle>Media Library</DialogTitle>
      <DialogContent>

        <form>
          <Grid container spacing={2}>
            <Grid item xs={4}>
              <FormControl fullWidth variant="standard">
                <InputLabel>Experience</InputLabel>
                <Select {...register("experience")} defaultValue="all">
                  {experienceOptions.map((option) => (
                    <MenuItem key={option._id} value={option._id}>
                      {option.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={4}>
              <FormControl fullWidth variant="standard">
                <InputLabel>Media Type</InputLabel>
                <Select {...register("type")} defaultValue="All">
                  {mediaTypes.map((type, index) => (
                    <MenuItem key={index} value={type}>{type}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={4}>
              <FormControl fullWidth variant="standard">
                <InputLabel>Sort By</InputLabel>
                <Select {...register("sortBy")} defaultValue="Date Added">
                  {sortOptions.map((sortOption) => (
                    <MenuItem key={sortOption} value={sortOption}>{sortOption}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </form>


        <Grid container spacing={2} style={{ marginTop: '16px' }}>
          {loading ? (
            <CircularProgress />
          ) : (
            <Grid container spacing={2}>
              {filteredMedia.map((mediaItem, index) => (
                <Grid item xs={12} key={mediaItem._id ? mediaItem._id : index}>
                  <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                    <div style={{ display: 'flex', alignItems: 'center', flexGrow: 1 }}>
                      {(parent == "main" &&
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={selectedMediaId === mediaItem._id}
                              onChange={() => handleMediaSelect(mediaItem)}
                            />
                          }
                          //   label={mediaItem.md5}
                          style={{ marginRight: '10px' }} // Space between checkbox and label
                        />
                      )}
                      {(parent == "places" &&
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={selectedMediaSet.has(mediaItem._id)}
                              onChange={() => handleMultiSelectToggle(mediaItem)}
                            />
                          }
                          label=" "
                        />
                      )}
                      <MediaItem mediaItem={mediaItem} />
                    </div>
                    <Button onClick={() => handleDelete(mediaItem._id)} style={{ marginLeft: '10px' }}>
                      Delete
                    </Button>
                  </div>
                </Grid>
              ))}
            </Grid>
          )}
        </Grid>
      </DialogContent>
      <DialogActions>
        {parent === "main" ? (
          selectedMediaId ? (
            <>
              <Button onClick={handleCreateTextClick} variant="outlined" disabled={mediaItem?.mimetype !== 'text/html'}>
                Edit Text
              </Button>
              <Button onClick={handleOpenUploadDialog} variant="contained" disabled={mediaItem?.mimetype === 'text/html'}>
                Edit Media
              </Button>
              <Button onClick={onClose} variant="outlined">
                Done
              </Button>
            </>
          ) : (
            <>
              <Button onClick={handleClose} variant="outlined">
                Cancel
              </Button>
              <Button onClick={handleCreateTextClick} variant="outlined">
                Create Text
              </Button>
              <Button onClick={handleOpenUploadDialog} variant="contained">
                Upload Media
              </Button>
            </>
          )
        ) : (
          <>
            <Button onClick={handleClose} variant="outlined">
              Cancel
            </Button>
            <Button onClick={handleClose} variant="contained">
              Done
            </Button>
          </>
        )}
      </DialogActions>
      <UploadMedia open={uploadDialogOpen} onClose={handleCloseUploadDialog} selectedMediaId={selectedMediaId} mediaDescription={mediaDescription} experienceId={experienceId} onUploadSuccess={fetchMedia} mediaItem={mediaItem} />
      <TextMediaComponent
        open={isTextDialogOpen}
        onClose={handleCloseDialog}
        mediaItem={mediaItem}
        selectedMediaId={selectedMediaId}
        onSave={handleSaveText}
        onRefresh={fetchMedia}
      />
    </Dialog>
  );
};

export default MediaLibrary;
