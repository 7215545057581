import styled from 'styled-components';

import { createTheme } from '@mui/material/styles';

export const theme = createTheme({
    typography: {
      fontFamily: 'Roboto Sab',
  
    },
  });
  
export const Layout = styled.div`
  display: flex;
  
  `;

export const Content = styled.div`
  width: 100%;
  padding: 0px;
  `;
  