import React, { useState } from 'react';

import axios from 'axios';

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from '@mui/material';

import BASE_URL from '../../config';
import { showToast } from '../../utils/toast';

const CloneExperience = ({ isOpen, onClose, experienceId, onCloneSuccess }) => {
  const [experienceName, setExperienceName] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const resetForm = () => {
    setExperienceName("");
  };


  const handleClone = async () => {
    setLoading(true);
    const storedOwnerId = sessionStorage.getItem("ownerId");

    try {
      const response = await axios.post(
        `${BASE_URL}/api/experience/${experienceId}/clone`,
        {
          name: experienceName,
          user: { _id: storedOwnerId },
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
          withCredentials: true,
        }
      );

      if (response.status === 200) {
        onCloneSuccess(response.data);
        showToast('success', 'Experience cloned successfully!');
        resetForm();
      } else {
        throw new Error(`Failed to clone experience: ${response.statusText}`);
      }
    } catch (error) {
      console.error("Error cloning experience:", error);
      setError("Error in cloning the experience");
    } finally {
      setLoading(false);
      //   onClose();
    }
  };



  return (
    <Dialog open={isOpen} onClose={onClose}>
      <DialogTitle>Clone Experience</DialogTitle>
      <DialogContent>
        {error && <p className="error-message">{error}</p>}
        <TextField
          label="Name your new experience"
          variant="standard"
          value={experienceName}
          onChange={(e) => setExperienceName(e.target.value)}
          fullWidth
          margin="normal"
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} variant="outlined">
          Cancel
        </Button>
        <Button onClick={handleClone} variant="contained" color="primary" disabled={!experienceName}>
          {loading ? "Cloning..." : "Clone"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CloneExperience;
