import React, {
  useEffect,
  useRef,
  useState,
} from 'react';

import axios from 'axios';
import { ChromePicker } from 'react-color';
import { MdArrowBack } from 'react-icons/md';
import styled from 'styled-components';

import {
  Button,
  IconButton,
  TextField,
  Typography,
} from '@mui/material';

import BASE_URL from '../../config';
import { showToast } from '../../utils/toast';
import { useColor } from './ColorContext';

const RouteForm = styled.form`
  display: flex;
  flex-direction: column;
  margin-top: 10px;
`;

const FormField = styled.div`
  margin-bottom: 20px;
`;

const SidebarContainer = styled.div`
  width: ${(props) => (props.$isCollapsed ? "0" : "20%")};
  background: white;
  color: black;
  display: flex;
  flex-direction: column;
  transition: width 0.3s ease;

  @media (max-width: 768px) {
    width: ${(props) => (props.$isCollapsed ? "0" : "100%")};
  }

  @media (min-width: 769px) and (max-width: 1024px) {
    width: ${(props) => (props.$isCollapsed ? "0" : "50%")};
  }
`;

const EditRouteContainer = styled.div`
  padding: 20px;
  font-family: 'Roboto Slab';
`;

const EditRouteHeader = styled.header`
  display: flex;
  align-items: center;
  background-color: lightgrey;
  padding: 10px;
  margin-bottom: 10px;
`;

const ColorPreviewBox = styled.div`
  width: 24px;
  height: 24px;
  background-color: ${(props) => props.color || '#1976d2'};
  border-radius: 4px;
  margin-right: 10px;
  cursor: pointer;
`;

const CreateRoute = ({ onBack, routePoints, isEditing, routeData }) => {
  const [routeName, setRouteName] = useState(routeData?.name || '');
  const [description, setDescription] = useState(routeData?.description || '');
  //   const [color, setColor] = useState('#1976d2'); // Default color
  const { polylineColor, setPolylineColor } = useColor();
  //   const { color, setColor } = useColor();
  const [isColorPickerVisible, setIsColorPickerVisible] = useState(false);
  //   console.log("from create routePoints");
  //   console.log(routePoints);
  const colorPickerRef = useRef(null);
  const storedExperienceId = sessionStorage.getItem("experienceId");

  const handleColorChange = (color) => {
    // setColor(color.hex);
    setPolylineColor(color.hex);
  };
  const resetFields = () => {
    setRouteName(''); // Clear the type name
    setDescription([]); // Clear the uploaded files
    setPolylineColor('#1976d2');
    // setRoutePoints([]);
    // setError(''); // Clear any existing error messages
  };
  const handleColorPickerToggle = () => {
    setIsColorPickerVisible(!isColorPickerVisible);
  };

  useEffect(() => {
    if (routeData?.colour) {
      setPolylineColor(routeData.colour);
    }
  }, [routeData, setPolylineColor]);
  // Handle click outside to close the color picker
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (colorPickerRef.current && !colorPickerRef.current.contains(event.target)) {
        setIsColorPickerVisible(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    // console.log(routePoints);
    const routeData = {
      name: routeName,
      description,
      colour: polylineColor,
      experienceId: storedExperienceId,
      direction: 'None',
      geo: {
        type: 'LineString',
        coordinates: routePoints.map(point => [point.lng, point.lat]),
      },
    };
    try {
      const response = await axios.post(`${BASE_URL}/api/route`, routeData, {
        headers: {
          "Content-Type": "application/json",
        },
        withCredentials: true, // Correctly send credentials
      });

      // Check for successful response
      if (response.status !== 200) {
        throw new Error('Failed to create place');
      }

      const data = response.data; // Get data directly from the response
      // alert('Routes created successfully'); // Notify user of success
      showToast('success', 'Routes created successfully!');
      resetFields();
      // console.log(data); // Log the data returned from the server
      onBack();
    } catch (error) {
      console.error("Error details:", error); // Log error details for debugging
      // alert(error.response?.data?.error || error.message); // Show a meaningful error message
      showToast('error', 'Failed to create routes!');
    }
    // onCreateSubmit({ name: routeName, description, polylineColor });
  };
  const handleEditPlace = async (e) => {
    e.preventDefault();
    // console.log(routeData);
    const poiId = isEditing && routeData?._id;
    const updatedRouteData = {
      name: routeName,
      description,
      colour: polylineColor,
      experienceId: storedExperienceId,
      direction: 'None',
      geo: {
        type: 'LineString',
        coordinates: routePoints.map(point => [point.lng, point.lat]),
      },
    };
    try {
      const response = await axios.put(`${BASE_URL}/api/route/${poiId}`, updatedRouteData, {

        headers: {
          "Content-Type": "application/json",
        },
        withCredentials: true, // Correctly send credentials
      });

      // Check for successful response
      if (response.status !== 200) {
        throw new Error('Failed to update place');
      }

      const data = response.data; // Get data directly from the response
      // alert('Routes updated successfully'); // Notify user of success
      showToast('success', 'Routes updated successfully!');
      // console.log(data); // Log the data returned from the server
      // return data;
      resetFields();
      onBack();
    } catch (error) {
      console.error("Error details:", error); // Log error details for debugging
      showToast('error', 'Failed to update routes!');
      // alert(error.response?.data?.error || error.message); // Show a meaningful error message
    }
    // onCreateSubmit({ name: routeName, description, polylineColor });
  };

  return (
    <SidebarContainer>
      <EditRouteContainer>
        <EditRouteHeader>
          <IconButton onClick={onBack} style={{ marginRight: '10px' }}>
            <MdArrowBack />
          </IconButton>
          <Typography variant="h6">{isEditing ? 'Edit' : 'Create'} Route</Typography>
        </EditRouteHeader>

        <Typography variant="body2" style={{ marginBottom: '10px' }}>
          Click the map to start your route, then keep clicking to create more lines.
        </Typography>

        <RouteForm>
          <FormField>
            <TextField
              label="Name"
              value={routeName}
              onChange={(e) => setRouteName(e.target.value)}
              variant="standard"
              fullWidth
            />
          </FormField>

          <FormField>
            <TextField
              label="Description"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              variant="standard"
              multiline
              rows={2}
              fullWidth
            />
          </FormField>

          <FormField>
            <Typography variant="subtitle2">Color</Typography>
            <div
              style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}
              onClick={handleColorPickerToggle}
            >
              <ColorPreviewBox color={polylineColor} />
              <TextField
                label=""
                value={polylineColor}
                readOnly
                variant="standard"
                fullWidth
              // style={{ backgroundColor: color }}
              />
            </div>
            {isColorPickerVisible && (
              <div ref={colorPickerRef} style={{ position: 'absolute', zIndex: 2 }}>
                <ChromePicker color={polylineColor} onChangeComplete={handleColorChange} />
              </div>
            )}
          </FormField>

          <Button
            variant="contained"
            color="primary"
            type="submit"
            disabled={!routeName || !description || routePoints?.length === 0}
            onClick={isEditing ? handleEditPlace : handleSubmit}
          >
            {isEditing ? 'Edit' : 'Create'}
          </Button>
        </RouteForm>
      </EditRouteContainer>
    </SidebarContainer>
  );
};

export default CreateRoute;
