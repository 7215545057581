import React, { useState } from 'react';

import {
  Box,
  Button,
  Grid,
  Snackbar,
  TextField,
  Typography,
} from '@mui/material';

import ProjectService from './ProjectService';

const ProjectViewDetails = ({ project }) => {
  const [description, setDescription] = useState(project.description || '');
  const [iOS, setIOS] = useState(project.iOS || {});
  const [android, setAndroid] = useState(project.android || {});
  const [snackBarOpen, setSnackBarOpen] = useState(false);

  const handleSubmit = () => {
    // Only include fields that are editable
    const updatedProject = {
      description,
      iOS,
      android,
    };
    
    ProjectService.editProjectById(project._id, updatedProject).then(() => {
      setSnackBarOpen(true);
    });
  };

  return (
    <Box p={2}>
      <Typography variant="h6" gutterBottom>
        Edit Project Details
      </Typography>

      <Grid container spacing={2} mt={1}>
        <Grid item xs={12}>
          <TextField
            label="Description"
            multiline
            variant="standard"
            fullWidth
            value={description}
            onChange={(e) => setDescription(e.target.value)}
          />
        </Grid>
        
        <Grid item xs={12}>
          <TextField
            label="iOS App Store ID"
            variant="standard"
            fullWidth
            value={iOS.appStoreId || ''}
            onChange={(e) => setIOS({ ...iOS, appStoreId: e.target.value })}
          />
        </Grid>
        
        <Grid item xs={12}>
          <TextField
            label="Android Package Name"
            variant="standard"
            fullWidth
            value={android.package || ''}
            onChange={(e) => setAndroid({ ...android, package: e.target.value })}
          />
        </Grid>
        
        <Grid item xs={12} display="flex" justifyContent="flex-start" mt={2}>
          
          <Button 
            variant="contained" 
            color="primary" 
            onClick={handleSubmit} 
            disabled={!description}
          >
            Save
          </Button>
        </Grid>
      </Grid>

      <Snackbar
        open={snackBarOpen}
        autoHideDuration={3000}
        onClose={() => setSnackBarOpen(false)}
        message="Project updated successfully"
      />
    </Box>
  );
};

export default ProjectViewDetails;
