import BASE_URL from '../../../config';

export const handleLogout = async () => {
    try {
      const response = await fetch(`${BASE_URL}/api/auth/logout`, {
        method: "GET",
        credentials: "include",
      });
  
      if (response.ok) {
        sessionStorage.removeItem("experienceName");
        sessionStorage.removeItem("experienceId");
        sessionStorage.removeItem("experienceDesc");
        sessionStorage.removeItem("ownerId");
        window.location.href = "/";
      } else {
        // Handle logout error
        console.error("Logout failed");
      }
    } catch (error) {
      console.error(
        "Error during logout:",
        error.response ? error.response.data : error.message
      );
    }
  };
  
