import 'filepond/dist/filepond.min.css';
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css';

import React, {
  useEffect,
  useState,
} from 'react';

import axios from 'axios';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
import {
  FilePond,
  registerPlugin,
} from 'react-filepond';

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material';

import BASE_URL from '../../config';
import { showToast } from '../../utils/toast';

registerPlugin(FilePondPluginImagePreview);

const UploadMedia = ({ open, onClose, mediaDescription, experienceId, onUploadSuccess, mediaItem, selectedMediaId }) => {
  const [file, setFile] = useState(null);
  const [loading, setLoading] = useState(false);
  const storedOwnerId = sessionStorage.getItem("ownerId");
  // Handler for FilePond to update the single file
  const handleFileChange = (fileItems) => {
    setFile(fileItems.length > 0 ? fileItems[0].file : null);  // Get the selected file
  };

  useEffect(() => {
    if (mediaItem && selectedMediaId) {
      axios.get(mediaItem.path, { responseType: 'blob' })
        .then(response => setFile(response.data))
        .catch(error => console.error('Error fetching media:', error));
    } else {
      setFile(null);
    }
  }, [mediaItem, selectedMediaId]);

  const handleUpload = async () => {
    if (!file) return;

    setLoading(true);
    const formData = new FormData();
    formData.append('filepond', file);  // Single file upload
    formData.append('description', mediaDescription);  // Attach description

    try {
      if (mediaItem && selectedMediaId) {
        // Upload new media item
        const response = await axios.put(`${BASE_URL}/api/media/files/${selectedMediaId}`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
          withCredentials: true,
        });

        // console.log('Media updated successfully:', response.data);
        showToast('success', 'Media updated successfully!');

      } else {
        formData.append('expId', experienceId);  // Attach experience ID
        const response = await axios.post(`${BASE_URL}/api/media/process?expId=${experienceId}`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
          withCredentials: true,
        });
        showToast('success', 'Media uploaded successfully!');
        // console.log('Media uploaded successfully:', response.data);
      }
      onClose();
      onUploadSuccess();
      setFile(null);
    } catch (error) {
      showToast('error', 'Media uploading failed!');
      console.error('Error uploading media:', error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="xs" fullWidth>
      <DialogTitle>{(mediaItem && selectedMediaId) ? 'Edit' : 'Add'} Media</DialogTitle>
      <DialogContent>
        <p>You can upload text (.txt), image (.png, .jpeg), video (.mp4), audio (.mp3), or PDF (.pdf) files</p>
        <FilePond
          files={file ? [file] : []}  // Only display the single file
          onupdatefiles={handleFileChange}  // Handle file changes
          allowMultiple={false}  // Only allow one file
          name="file"  // Form name must match the backend's expected field
          labelIdle='Drag & Drop your file or <span class="filepond--label-action">Browse</span>'
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleUpload} disabled={!file || loading}>
          {loading ? 'Uploading…' : 'Upload'}
        </Button>
        <Button onClick={onClose} disabled={loading}>Cancel</Button>
      </DialogActions>
    </Dialog>
  );
};

export default UploadMedia;
